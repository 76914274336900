<template>
    <div class="pageContainer cbox syssy">
        <div class="tabBox flexBetween">
            <div class="flexStart" style="padding-left: 15px">
                <el-breadcrumb>
                    <el-breadcrumb-item>实验室管理</el-breadcrumb-item>
                    <el-breadcrumb-item>参观接待记录</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="tabContent flexCloumn">
            <el-row class="flexBetween" style="flex-wrap: wrap; width: 100%">
                <div class="flex_1 felxStart">
                    <span> 学期：</span>
                    <el-select size="small" v-model="queryForm.term_id" filterable placeholder="学期" style=""
                        @change="$forceUpdate()">
                        <el-option v-for="(item, index) in termList" :key="index" :label="item.title"
                            :value="item.id"></el-option>
                    </el-select>

                    <el-button class="ml10" size="small" type="primary" icon="el-icon-search"
                        style="display: inline-block" @click="queryCourseTable">查询</el-button>

                    <el-button class="ml10" size="small" type="primary" icon="el-icon-plus"
                        style="display: inline-block" @click="addNew">新增</el-button>
                </div>

                <!-- <div v-if="!cgjvList || cgjvList.length == 0" class="item" style="display: inline-block">
                    <el-button type="text" size="small">设备损坏记录表</el-button>
                    <i class="el-icon-download"></i>
                </div> -->

                <div class="flexStart">
                    <el-button size="small" type="primary" plain style="display:inline-block;margin-right:20px;"
                        icon="el-icon-upload" @click="showImportDialog = true">导入
                    </el-button>
                    <div v-if="cgjvList && cgjvList.length > 0" class="item" style="display: inline-block">
                        <el-button type="text" size="small" @click="exportExcel()">
                            <a href="javascript:;" style="text-decoration: none">{{ sysName }}参观接待记录</a>
                        </el-button>
                        <i class="el-icon-download"></i>
                    </div>
                </div>
            </el-row>

            <div class="flex_1" style="width: 100%; height: calc(100% - 40px)">
                <div class="flexStretch" style="width: 100%; height: 100%">
                    <div class="dialog_left">
                        <div class="bgGrey">
                            <div class="flexStartUp" style="width: 100%; height: 50px; padding-left: 15px">
                                <el-select v-model="sysID" filterable placeholder="请选择实验室" @change="chooseSys"
                                    size="small" style="width: calc(100% - 21px)">
                                    <el-option v-for="item in sysList" :key="item.id"
                                        :label="`${item.address}-${item.room_name}`" :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="listBox">
                                <div :class="['listItem flexStart', activeIndex == index ? 'activeItem' : '',]"
                                    v-for="(item, index) in sysList" :key="index"
                                    @click="() => { getSchoolTimetable(item) }">
                                    <div class="dotted"></div>
                                    <div class="title flex_1">{{ `${item.address}-${item.room_name}` }}</div>
                                    <i class="icon el-icon-right" v-if="activeIndex == index"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="dialog_right">
                        <div class="bcontent">
                            <div class="flexCloumn" style="margin-top: 20px; width: 100%; height: calc(100% - 20px)">
                                <div class="flexCenter" style="
                    width: 100%;
                    text-align: center;
                    padding-bottom: 20px;
                    font-size: 20px;
                  ">
                                    {{ sysName }}
                                </div>
                                <el-table :data="cgjvList" height="100%" stripe border size="small"
                                    style="width: fit-content; width: 100%">
                                    <el-table-column type="index" label="序号" width="55"
                                        align="center"></el-table-column>
                                    <el-table-column prop="date" label="日期" width="120" align="center">
                                        <template slot-scope="scope">
                                            <span style="font-size: 14px;">{{ scope.row.date }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="content" label="内容" max-width="150" align="center">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.content && scope.row.content.length > 20">
                                                <el-popover placement="bottom-start" title="" trigger="hover"
                                                    popper-class="workorder-remark">
                                                    <div>{{ scope.row.content }}</div>
                                                    <span slot="reference">
                                                        {{ scope.row.content.substr(0,20) + '...' }}
                                                    </span>
                                                </el-popover>
                                            </span>
                                            <span v-else>{{ scope.row.content }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="cgdw" label="参观单位" min-width="80" show-overflow-tooltip
                                        align="center"></el-table-column>
                                    <el-table-column prop="num" label="参观人数" width="100"
                                        align="center"></el-table-column>
                                    <el-table-column prop="cgdb" label="参观代表签字" width="120"
                                        align="center"></el-table-column>
                                    <el-table-column prop="sign" label="学校带队人签字" width="150" align="center">
                                        <template slot-scope="scope">
                                            <img style="width: 90px;" :src="scope.row.handsign" alt="">
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="操作" width="130" fixed="right" align="center">
                                        <template slot-scope="scope">
                                            <el-tag size="mini" type="danger" style="cursor: pointer"
                                                @click="deleteItem(scope.row)"><i class="el-icon-delete"></i>删除</el-tag>

                                            <el-tag v-if="userinfo.id == scope.row.teacher_id" size="mini"
                                                type="success" style="margin-left: 5px; cursor: pointer"
                                                @click="editRow(scope.row)"><i class="el-icon-delete"></i>编辑</el-tag>
                                        </template>
                                    </el-table-column>
                                </el-table>

                                <el-pagination class="cus_page" :page-sizes="[10, 20, 50, 100]"
                                    :page-size="page.per_page" layout="total" :total="page.count"
                                    style="margin-top: 10px">
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog title="新增参观记录" :visible.sync="dialogVisible" custom-class="width_800 cus_dialog"
            :close-on-click-modal="false" @close="onClose">
            <el-form :model="EditItem" label-width="120px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="学期" required>
                            <el-select v-model="EditItem.term_id">
                                <el-option :label="item.title" :value="item.id" v-for="(item, i) in termList"
                                    :key="i"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="日期" required>
                            <el-date-picker v-model="EditItem.date" value-format="yyyy-MM-dd" type="date"
                                placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="实验室" required>
                            <el-select v-model="EditItem.room_id" filterable>
                                <el-option :label="item.room_name" :value="item.roomid" v-for="(item, i) in sysList"
                                    :key="i"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="参观单位" required>
                            <el-input v-model="EditItem.cgdw" placeholder="请输入参观单位"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="参观人数" required label-width="150px">
                            <el-input-number :min="1" v-model="EditItem.num"></el-input-number>
                        </el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-form-item label="内容" required>
                            <el-input v-model="EditItem.content" type="textarea" placeholder="请输入内容"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="参观代表签字">
                            <el-input v-model="EditItem.cgdb" placeholder="请输入参观代表"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-form-item label="学校带队人签字">
                            <el-select v-model="EditItem.ddteacher_id" filterable>
                                <el-option :label="item.username" :value="item.id" v-for="(item, i) in teacherList"
                                    :key="i"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>


                <el-row style="text-align: right">
                    <el-col :span="20">
                        <el-button type="primary" icon="el-icon-check" style="display: inline-block"
                            @click="saveSysMaintain">提交</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>

        <el-dialog :visible.sync="showImportDialog" :close-on-click-modal="false" :destroy-on-close="true"
            custom-class="cus_dialog cus_dialogMin" width="600px">
            <div slot="title" class="flexBetween">
                <span style="color: #ffffff;">导入实验室参观接待记录表</span>
            </div>

            <el-form :model="importForm" size="small" label-width="110px" style="overflow-y: auto;"
                label-position="left">

                <el-form-item label="下载模板" class="">
                    <a href="./downloadFiles/实训基地参观接待记录导入模板.xlsx" target="_blank"
                        style="text-decoration: #3378fa; color:#3378fa;font-size: 14px;">
                        <i class="el-icon-download"></i> 下载模板</a>
                </el-form-item>

                <el-form-item label="选择学期" class="required_form" style="overflow:hidden">
                    <el-select size="small" v-model="importForm.term_id" filterable placeholder="学期" style=""
                        @change="$forceUpdate()">
                        <el-option v-for="(item, index) in termList" :key="index" :label="item.title"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="上传文件" class="required_form" style="overflow:hidden">
                    <el-upload action="" :http-request="uploadTemp" :on-success="uploadFiles" :show-file-list="false">
                        <el-button size="small" type="primary" plain>选择文件</el-button>
                    </el-upload>
                    <div style="font-size:14px;color:#606266;line-height: normal;"> {{ importForm.fileName }}
                    </div>

                    <!-- <input type="file" @change="handleFileData(this)" style=""
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" /> -->
                </el-form-item>
            </el-form>




            <div slot="footer" class="dialog-footer">
                <el-button @click="showImportDialog = false" type="primary" plain>关 闭</el-button>
                <el-button @click="saveFormImport('import')" type="primary" icon="el-icon-upload">确定导入</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import DeptUser from "../../../com/deptuser.vue";
export default {
    components: {
        DeptUser,
    },
    data() {
        return {
            userinfo: {},
            dialogVisible: false,
            report_year: "",
            week: "",
            year_week: new Date(),
            sysID: 0,
            sysName: "",
            sysList: [],
            courseList: [],
            activeTabIndex: 0,
            activeIndex: 0, //教室
            queryForm: {},
            termList: [],
            termIdInit: "",
            value: "",
            cgjvList: [],
            sysInfo: {},
            EditItem: {
                num: 1
            },
            teacherList: [],

            page: {
                current_page: 1,
                per_page: 20,
                count: 0,
                pageSizes: [10, 50, 100],
            },
            showImportDialog: false,
            importForm: {},
            termList: [],
            termIdInit: ''
        };
    },
    mounted() {
        this.getTerm().then((_) => {
            this.getSysList();
        });

        this.getteacherList()
        this.$http.post("/api/get_teacher_info", {}).then((res) => {
            this.userinfo = res.data;
        });

        if (this.$route.query.roomId) {
            this.sysID = Number(this.$route.query.roomId);
        }
    },
    methods: {

        getteacherList() {
            this.$http.post('/api/t_sch_teachers_simple_list').then((res) => {
                this.teacherList = res.data.data;
            })
        },
        addNew() {
            this.EditItem.term_id = this.queryForm.term_id;
            this.EditItem.room_id = this.sysID;
            this.EditItem.date = this.$moment().format('YYYY-MM-DD')
            this.dialogVisible = true;
        },
        getcgjvList() {
            this.$http
                .post("/api/t_sch_sysrooms_cg_list", {
                    pagesize: 90000000,
                    term_id: this.queryForm.term_id,
                    room_id: this.sysID,
                })
                .then((res) => {
                    this.cgjvList = res.data.data;
                    this.page.count = res.data.data.length;
                });
        },

        getTerm() {
            return new Promise((resolve) => {
                this.$http.post(`/api/t_sch_terms_list`).then((res) => {
                    if (res && res.data.data && res.data.data.length > 0) {
                        this.termList = res.data.data;
                        res.data.data &&
                            res.data.data.map((a) => {
                                if (
                                    this.globalCurrentDate > a.term_start &&
                                    this.globalCurrentDate < a.term_end
                                ) {
                                    this.termIdInit = a.id;
                                    this.queryForm.term_id = this.termIdInit;
                                    this.importForm.term_id = this.termIdInit
                                }
                            });
                        if (!this.termIdInit) {
                            this.termIdInit = res.data.data[0].id;
                            this.queryForm.term_id = this.termIdInit;
                            this.importForm.term_id = this.termIdInit
                        }
                    }
                    this.$forceUpdate();
                    resolve();
                });
            });
        },
        getSysList() {
            this.$http
                .post("/api/syskcb_rooms", {
                    pagesize: 2000,
                    term_id: this.queryForm.term_id,
                })
                .then((res) => {
                    this.sysList = res.data;
                    if (this.sysID) {
                        this.sysName = this.sysList
                            .filter((s) => s.id === this.sysID)
                            .map((s) => s.room_name);
                        this.sysInfo = this.sysList.filter((s) => s.id === this.sysID)[0];
                    } else if (this.sysList.length > 0) {
                        this.sysID = this.sysList[0].id;
                        this.sysName = this.sysList[0].room_name;
                        this.sysInfo = this.sysList[0];
                    }
                    this.getData();
                });
        },

        getData() {
            //获取采购清单
            this.cgjvList = [];
            this.$forceUpdate();
            this.getcgjvList();
        },

        queryCourseTable() {
            this.sysID = "";
            this.getSysList();
        },

        chooseSys() {
            this.sysList.map((a, ia) => {
                if (this.sysID == a.id) {
                    this.sysInfo = a;
                    this.sysID = a.id;
                    this.sysName = a.room_name;
                    this.activeIndex = ia;
                    this.getData();
                    this.jump(ia);
                    return;
                }
            });
        },
        getSchoolTimetable(row) {
            this.sysInfo = row;
            this.sysID = row.id;
            this.sysName = row.room_name;
            this.sysList.map((a, ia) => {
                if (a.id == row.id) {
                    this.activeIndex = ia;
                    return;
                }
            });
            this.getData();
        },

        changeDate(e) {
            let str = this.$moment(this.year_week).utcOffset(480).format("yyyy-WW");
            this.weekStart = this.$moment(this.year_week)
                .utcOffset(480)
                .format("yyyy-MM-DD");
            this.weekEnd = this.$moment(this.year_week)
                .add(6, "day")
                .utcOffset(480)
                .format("yyyy-MM-DD");

            let arr = str.split("-");
            this.report_year = arr[0];
            this.week = arr[1];

            this.getData();
        },

        deleteItem(e) {
            this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.$http
                    .post("/api/t_sch_sysrooms_cg_list_delete", { id: e.id })
                    .then((res) => {
                        this.$message.success("删除成功");
                        this.getData();
                    });
            });
        },
        editRow(e) {
            this.EditItem = e;
            console.log(this.EditItem);
            this.dialogVisible = true;
        },

        jump(index) {
            let target = document.querySelector(".listBox");

            let scrollItems = document.querySelectorAll(".listItem");

            // 判断滚动条是否滚动到底部

            if (target.scrollHeight <= target.scrollTop + target.clientHeight) {
                this.activeStep = index;
            }

            let total = scrollItems[index].offsetTop - scrollItems[0].offsetTop; // 锚点元素距离其offsetParent(这里是body)顶部的距离(待滚动的距离)

            let distance = document.querySelector(".listBox").scrollTop; // 滚动条距离滚动区域顶部的距离

            // let distance = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset // 滚动条距离滚动区域顶部的距离(滚动区域为窗口)

            // 滚动动画实现, 使用setTimeout的递归实现平滑滚动，将距离细分为50小段，10ms滚动一次

            // 计算每一小段的距离

            let step = total / 50;

            if (total > distance) {
                smoothDown(document.querySelector(".listBox"));
            } else {
                let newTotal = distance - total;

                step = newTotal / 50;

                smoothUp(document.querySelector(".listBox"));
            }

            // 参数element为滚动区域

            function smoothDown(element) {
                if (distance < total) {
                    distance += step;

                    element.scrollTop = distance;

                    setTimeout(smoothDown.bind(this, element), 10);
                } else {
                    element.scrollTop = total;
                }
            }

            // 参数element为滚动区域

            function smoothUp(element) {
                if (distance > total) {
                    distance -= step;

                    element.scrollTop = distance;

                    setTimeout(smoothUp.bind(this, element), 10);
                } else {
                    element.scrollTop = total;
                }
            }
        },

        toBase64(url) {
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.onload = function () {
                    const reader = new FileReader();
                    reader.onloadend = function () {
                        let dataURL = reader.result;
                        const base64Regex =
                            /^data:image\/(png|jpg|jpeg|svg|svg\+xml);base64,/;
                        if (!base64Regex.test(dataURL)) {
                            return false;
                        }
                        const stringBase64 = dataURL.replace(base64Regex, "");
                        let binaryString;
                        if (typeof window !== "undefined") {
                            binaryString = window.atob(stringBase64);
                        } else {
                            binaryString = Buffer.from(stringBase64, "base64").toString(
                                "binary"
                            );
                        }
                        const len = binaryString.length;
                        const bytes = new Uint8Array(len);
                        for (let i = 0; i < len; i++) {
                            const ascii = binaryString.charCodeAt(i);
                            bytes[i] = ascii;
                        }
                        resolve(bytes.buffer);
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(xhr.response);
                };
                xhr.onerror = reject;
                xhr.open("GET", url);
                xhr.responseType = "blob";
                xhr.send();
            });
        },

        convertImageToBase64(url, callback) {
            fetch(url)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(
                            "Network response was not ok " + response.statusText
                        );
                    }
                    console.log(1936, response);
                    response.body();
                })
                .then((blob) => {
                    console.log(":::::::::::::::");
                    const reader = new FileReader();
                    reader.onload = (e) => callback(e.target.result);
                    reader.readAsDataURL(blob);
                })
                .catch((error) =>
                    console.error("Error converting image to base64:", error)
                );
        },

        async exportExcel() {
            this.$http
                .post("/api/export_sysrooms_cg_list_excel", {
                    term_id: this.queryForm.term_id,
                    room_id: this.sysID,
                })
                .then((res) => {
                    window.open(res.data.url, "_blank");
                });
        },
        saveSysMaintain() {
            // this.EditItem.term_id = this.termIdInit;
            if (!this.EditItem.date) {
                this.$message.error("请选择日期");
                return;
            }
            if (!this.EditItem.cgdw) {
                this.$message.error("请输入参观单位");
                return;
            }
            if (!this.EditItem.ddteacher_id) {
                this.$message.error("请选择带队老师");
                return;
            }
            if (!this.EditItem.num) {
                this.$message.error("请输入参观人数");
                return;
            }
            if (!this.EditItem.room_id) {
                this.$message.error("请选择实验室!");
                return;
            }
            this.$http
                .post("/api/t_sch_sysrooms_cg_list_edit", this.EditItem)
                .then((res) => {
                    this.getData();
                    this.$message.success("提交成功");
                    this.EditItem = { num: 1 };
                    this.dialogVisible = false;
                });
        },
        // getHcPrice(hc_id) {
        //     for (let i = 0; i < this.teacherList.length; i++) {
        //         const item = this.teacherList[i];
        //         if (item.id == hc_id) {
        //             return parseInt(item.jine)
        //         }
        //     }
        //     return 0
        // },
        onClose() {
            this.EditItem = {};
        },


        uploadFiles(e) {
            console.log(e)
            this.importForm.fileName = e.fileName
            this.importForm.url = e.src
            this.$forceUpdate()
        },

        saveFormImport(action) {
            let _this = this
            console.log(476, this.importForm.term_id)
            let flag = 1
            let arr = []
            if (!this.importForm.term_id) {
                flag = 0; this.$message.error(`请选择关联学期~`); return;
            }


            let termObj = this._lo.find(_this.termList, function (o) { return o.id == _this.importForm.term_id; })


            if (!this.importForm.url || this.importForm.url == '') {
                flag = 0; this.$message.error(`请选择excel文件并上传~`); return;

            }


            if (flag) {
                console.log(333, arr)
                this.$confirm(`您已选择【${termObj.title}】，是否确定导入该学期的实验室参观接待记录情况？`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {


                    let loadingInstance = this.$loading({
                        lock: true,
                        text: "正在导入，请稍等....",
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.3)',
                    })
                    this.$http
                        .post('/api/sysrooms_cg_import_xls', {
                            term_id: this.importForm.term_id,
                            url: this.importForm.url,
                        })
                        .then((res) => {
                            if (res.data.errors && res.data.errors.length > 0) {
                                let html = "";
                                for (let msg of res.data.errors) {
                                    html +=
                                        "<div style='font-size:12px'>" + msg + "</div>";
                                }
                                this.$alert(html, "未导入数据", {
                                    dangerouslyUseHTMLString: true,
                                });
                            } else {
                                this.$message.success("导入成功");
                                this.getData();
                                this.showImportDialog = false;
                            }
                        });
                    loadingInstance.close()
                })
            }
        },
    },
};
</script>
<style lang="less" type="text/less">
.syssy {
    .el-input.is-disabled .el-input__inner {
        color: #606266;
    }
}

.cepContact .el-col {
    display: flex;
    padding: 0;
    margin: 0;
}

.cepContact .el-row {
    width: 100%;
    padding: 0;
    margin: 0;
}
</style>
<style scoped="scoped" lang="less" type="text/less">
.bgLineHeight {
    background-color: rgba(24, 144, 255, 0.08);
}

.cepContact {
    margin-top: 25px;
    width: 100%;
    height: calc(100% - 25px);
    overflow-y: auto;

    .title {
        font-size: 20px;
        line-height: 50px;
    }

    .form {
        width: 100% - 0px;
        border: 1px solid #c0d2ff;
        border-top: 0;

        div {
            font-size: 14px;
            color: #323232;
            box-sizing: border-box;
        }

        .content {
            width: 100%;
            height: 100%;
            padding: 10px;
            white-space: break-spaces;
            word-break: break-all;
            overflow: hidden;
        }
    }

    .col-row {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .table {
        width: 100%;
    }

    .borderR {
        border-right: 1px solid #c0d2ff;
    }

    .borderB {
        border-bottom: 1px solid #c0d2ff;
    }

    .cell {
        border-left: 1px solid #c0d2ff;
        border-top: 1px solid #c0d2ff;

        min-height: 30px;
        //line-height: 30px;
        //text-align: center;
        white-space: nowrap;
        font-size: 14px;
        word-break: break-all;
        white-space: normal;
    }

    .cell50 {
        min-height: 50px;
        line-height: 50px;
        font-size: 14px;
    }

    .cellMore {
        border-left: 1px solid #c0d2ff;
        border-top: 1px solid #c0d2ff;
        min-height: 50px;
        text-align: left;
        font-size: 14px;
    }
}

.dttable {
    /*table-layout: fixed;*/

    td {
        text-align: center;
        word-wrap: break-word;
        width: auto !important;
    }

    tbody td {
        /*cursor: pointer;*/
    }
}

.cbox {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0;

    div {
        box-sizing: border-box;
    }

    .el-table__empty-block {
        width: 100% !important;
    }

    .tabBox {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid #98b9fc;

        .tabBar {
            height: 100%;
            align-items: end;

            .tabItem {
                height: 30px;
                border: 1px solid #3f4bda;
                margin-left: 15px;
                padding: 0 5px;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                margin-bottom: -1px;
                cursor: pointer;
            }

            .activeTab {
                background-color: #2469f3;
                border: 0;
                color: #fff;
            }

            .activeTabTgt {
                background-color: #3e4ddb;
                border: 0;
                color: #fff;
            }
        }
    }

    .tabContent {
        padding: 20px 15px;
        height: calc(100% - 40px);

        .dialog_left {
            width: 300px;
            height: 100%;
            overflow: hidden;

            .bgGrey {
                margin-top: 20px;
                padding: 20px 0;
                height: calc(100% - 20px);
                border-radius: 15px;
                background-color: rgba(24, 144, 255, 0.08);

                .listBox {
                    height: 100%;
                    padding: 0 15px;
                    overflow-y: auto;
                    height: calc(100% - 50px);

                    .listItem {
                        margin-bottom: 20px;
                        cursor: pointer;

                        .dotted {
                            margin-right: 10px;
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            background-color: #7a7a7a;
                        }

                        .title {
                            color: #7a7a7a;
                            font-weight: 700;
                        }

                        .icon {
                            margin-left: 10px;
                            color: #3073f7;
                            font-weight: 700;
                        }
                    }

                    .activeItem {
                        .dotted {
                            background-color: #3073f7;
                        }

                        .title {
                            color: #3073f7;
                        }
                    }
                }
            }
        }

        .dialog_right {
            margin-left: 30px;
            width: calc(100% - 330px);
            height: 100%;
            overflow: hidden;

            .bcontent {
                width: 100%;
                height: 100%;
                overflow: hidden;
            }

            .formBox {
                width: 100%;
                height: calc(100% - 50px);
                margin-top: 30px;

                .tabBox {
                    width: 100%;
                    height: 40px;
                    border-bottom: 1px solid #98b9fc;
                    align-items: end;

                    .tabBar {
                        height: 100%;
                        align-items: end;
                        padding-left: 40px;

                        .tabItem {
                            height: 30px;
                            border: 1px solid #3f4bda;
                            margin-right: 15px;
                            padding: 0 5px;
                            border-top-left-radius: 8px;
                            border-top-right-radius: 8px;
                            margin-bottom: -1px;
                            cursor: pointer;
                            font-size: 14px;
                            white-space: nowrap;
                        }

                        .activeTab {
                            background-color: #2469f3;
                            border: 0;
                            color: #fff;
                        }

                        .activeTabTgt {
                            background-color: #3e4ddb;
                            border: 0;
                            color: #fff;
                        }
                    }
                }

                .tabContent {
                    width: 100%;
                    height: calc(100% - 100px);
                    padding-left: 40px;
                }
            }

            .targetItem {
                margin-bottom: 10px;
                width: 100%;
            }
        }
    }
}
</style>
