<template>
  <div class="cbox">
    <div class="headbar">
      <div style="float: right;">
        <div>
          <el-input v-model="queryForm.keyword" size="small" placeholder="请输入关键字"
            style="width:200px;margin-right:10px"></el-input>
          <el-button size="small" type="primary" icon="el-icon-search" style="display:inline-block" @click="getList">查询
          </el-button>
          <el-button size="small" type="primary" icon="el-icon-plus" style="display:inline-block" @click="addForm">添加
          </el-button>
          <el-button size="small" type="primary" plain style="display:inline-block;margin-left:20px;"
            icon="el-icon-upload" @click="showImportDialog = true">导入
          </el-button>

        </div>

      </div>
      <div style="padding-top: 14px;">
        <el-breadcrumb>
          <el-breadcrumb-item>实验室管理</el-breadcrumb-item>
          <el-breadcrumb-item>实验室隐患</el-breadcrumb-item>

        </el-breadcrumb>
      </div>

    </div>

    <div class="bcontent">

      <el-table :data="DataList" style="width: 100%;" stripe border size="small">
        <el-table-column type="index" label="编号" width="55" align="center"></el-table-column>


        <el-table-column prop="occurred_time" label="时间" width="195"></el-table-column>
        <el-table-column prop="teacher_name" label="检查人" width="120"></el-table-column>
        <el-table-column prop="room_name" label="实验室" width="150"></el-table-column>

        <el-table-column prop="content" label="隐患描述" min-width="300">
          <template slot-scope="scope">

            {{ scope.row.content }}

          </template>
        </el-table-column>


        <el-table-column prop="room_desc" label="隐患图片" width="120">
          <template slot-scope="scope">
            <el-image fit="fill" v-for="(f, i) in scope.row.files" style="width:40px;height:40px;margin-right: 5px;"
              :src="f.src" :preview-src-list="[f.src]"></el-image>
          </template>
        </el-table-column>


        <el-table-column label="操作" width="180" fixed="right">
          <template slot-scope="scope">
            <el-tag size="mini" style="cursor: pointer" @click="editItem(scope.row)"><i
                    class="el-icon-edit"></i></el-tag>

            <el-tag size="mini" type="warning" style="margin-left: 10px;cursor: pointer"
              @click="deleteItem(scope.row)"><i class="el-icon-delete"></i>取消隐患
            </el-tag>

          </template>
        </el-table-column>

      </el-table>

      <el-pagination class="cus_page" @size-change="handleSizeChange" @current-change="paginate"
        :current-page.sync="page.current_page" :page-sizes="[10, 50, 100]" :page-size="page.pre_page"
        layout="total,sizes, prev, pager, next" :total="page.count">
      </el-pagination>


      <div style="height: 20px;">


      </div>


    </div>


    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" custom-class="width_800 cus_dialog"
      :close-on-click-modal="false" @close="handleCloseDialog">
      <el-form :model="EditItem" :rules="rules" ref="ruleForm" label-width="120px">
        <el-row>
          <el-col :span="20">
            <el-form-item label="检查时间" prop="occurred_time">
              <el-date-picker v-model="EditItem.occurred_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="20">
            <el-form-item label="实验室" prop="room_id">
              <el-select v-model="EditItem.room_id" placeholder="请选择">
                <el-option v-for="item in rooms" :key="item.id" :label="item.room_name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="20">
            <el-form-item label="隐患描述" prop="content">
              <el-input type="textarea" v-model="EditItem.content" maxlength="2000" show-word-limit
                :autosize="{minRows: 2}" placeholder="请输入隐患描述"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="20">
            <el-form-item label="附件" prop="title">
              <div style="display:inline-block; margin-right:10px;vertical-align: top; position: relative;"
                v-for="(img, idx) in EditItem.files" :key="idx">
                <el-image style="width: 100px; height: 100px;" :src="img.fileUrl || img.src"
                  :preview-src-list="[img.fileUrl || img.src]" img="cover"></el-image>

                <div class="imgremove" @click="removeImg(idx)"><i class="el-icon-remove"></i></div>
              </div>
              <div style="display:inline-block;vertical-align: top;">
                <el-upload class="avatar-uploader" action="" :http-request="uploadOss" :on-success="uploadFiles"
                  :show-file-list="false" name="image">
                  <div class="imgaddbtn">
                    ＋
                  </div>
                </el-upload>
              </div>
            </el-form-item>
          </el-col>
        </el-row>


        <el-row style="text-align:right">
          <el-col :span="20">
            <el-button type="primary" icon="el-icon-check" style="display:inline-block" @click="saveForm">保存
            </el-button>
          </el-col>

        </el-row>

      </el-form>

    </el-dialog>




    <el-dialog :visible.sync="showImportDialog" :close-on-click-modal="false" :destroy-on-close="true"
      custom-class="cus_dialog cus_dialogMin" width="600px">
      <div slot="title" class="flexBetween">
        <span style="color: #ffffff;">导入实验室隐患记录</span>
      </div>

      <el-form :model="importForm" size="small" label-width="110px" style="overflow-y: auto;" label-position="left">

        <el-form-item label="下载模板" class="">
          <a href="./downloadFiles/实训基地隐患记录导入模板.xlsx" target="_blank"
            style="text-decoration: #3378fa; color:#3378fa;font-size: 14px;">
            <i class="el-icon-download"></i> 下载模板</a>
        </el-form-item>

        <!-- <el-form-item label="选择学期" class="required_form" style="overflow:hidden">
          <el-select size="small" v-model="importForm.term_id" filterable placeholder="学期" style=""
            @change="$forceUpdate()">
            <el-option v-for="(item, index) in termList" :key="index" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-form-item> -->

        <el-form-item label="上传文件" class="required_form" style="overflow:hidden">
          <el-upload action="" :http-request="uploadTemp" :on-success="uploadFiles1" :show-file-list="false">
            <el-button size="small" type="primary" plain>选择文件</el-button>
          </el-upload>
          <div style="font-size:14px;color:#606266;line-height: normal;"> {{ importForm.fileName }}
          </div>

          <!-- <input type="file" @change="handleFileData(this)" style=""
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" /> -->
        </el-form-item>
      </el-form>




      <div slot="footer" class="dialog-footer">
        <el-button @click="showImportDialog = false" type="primary" plain>关 闭</el-button>
        <el-button @click="saveFormImport('import')" type="primary" icon="el-icon-upload">确定导入</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";

export default {
  components: {},
  data() {
    return {
      queryForm: {
        keyword: "",
      },
      dialogVisible: false,
      showUserDialog: false,
      DataList: [],
      page: {
        current_page: 1,
        pre_page: 20,
        count: 0,
        pageSizes: [10, 50, 100]
      },

      EditItem: {
        room_id: "",
        occurred_time: moment().format('YYYY-MM-DD HH:mm:ss'),
        content: "",
        files: []
      },
      rooms: [],
      rules: {
        occurred_time: [
          {required: true, message: '请选择检查时间', trigger: 'change'}
        ],
        room_id: [
          {required: true, message: '请选择实验室', trigger: 'blur'}
        ],
        content: [
          {required: true, message: "请输入隐患描述", trigger: 'blur'}
        ]
      },
      dialogTitle: "添加",

      showImportDialog: false,
      importForm: {},
      termList: [],
      termIdInit: ''
    };
  },

  mounted() {

    // this.$http.post("/api/get_teacher_info", {

    // }).then(res => {
    //     this.userinfo = res.data
    //     this.getList()
    // })
    this.getList()
    this.getRoomList()
  },
  methods: {

    getList() {
      this.$http.post("/api/hidden_danger_lab_list", {
        page: this.page.current_page,
        pagesize: this.page.per_page,
        keyword: this.queryForm.keyword

      }).then(res => {
        let data = res.data.data
        data.map(item => { item.files = item.files?JSON.parse(item.files):[]})
        this.DataList = res.data.data

        this.page = res.data.page
      })
    },
    handleSizeChange(val) {
      this.page.current_page = 1
      this.page.pre_page = val
      this.getList();
    },
    paginate(val) {
      this.page.current_page = val
      this.getList();
    },
    addForm() {
      this.EditItem = {
        room_id: "",
        occurred_time: moment().format('YYYY-MM-DD HH:mm:ss'),
        content: "",
        files: []
      }
      this.dialogVisible = true
      this.dialogTitle = "添加"
    },
    saveForm() {
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          let params = this.EditItem
          this.$http.post('/api/hidden_danger_lab_edit',params).then(res => {
            this.$message.success('保存成功')
            this.getList()
            this.dialogVisible = false
          })
        }
      })
    },
    editItem(e) {
      this.EditItem = e;
      if (!this.EditItem.files) {
        this.EditItem.files = []
      }
      this.dialogVisible = true
      this.dialogTitle = "编辑"
    },
    deleteItem(e) {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('/api/hidden_danger_lab_delete', {id: e.id}).then(res => {
          this.$message.success('删除成功')
          this.getList()
        })
      })
    },
    getUser(e) {
      console.log('----------------')
      console.log(e)
      if (e && e.length > 0) {
        this.EditItem.teacher_id = e[0].id
        this.EditItem.username = e[0].username
      }
    },
    showDeptUserDialog() {
      //alert(111)
      this.showUserDialog = true
    },
    getRoomList() {
      this.$http.post("/api/t_sch_sysrooms_list", {
        page: 1,
        pagesize: 10000,
      }).then(res => {
        this.rooms = res.data.data
      })
    },
    uploadFiles(e) {
      this.EditItem.files.push(e)
    },
    removeImg(idx) {
      this.EditItem.files.splice(idx, 1)
    },
    handleCloseDialog() {
      if (this.$refs.ruleForm) {
        this.$nextTick(() => {
          this.$refs['ruleForm'].clearValidate()
        })
      }
    },
    uploadFiles1(e) {
      console.log(e)
      this.importForm.fileName = e.fileName
      this.importForm.url = e.src
      this.$forceUpdate()
    },

    saveFormImport(action) {
      let _this = this
      console.log(476, this.importForm.term_id)
      let flag = 1
      let arr = []
      // if (!this.importForm.term_id) {
      //   flag = 0; this.$message.error(`请选择关联学期~`); return;
      // }


      let termObj = this._lo.find(_this.termList, function (o) { return o.id == _this.importForm.term_id; })


      if (!this.importForm.url || this.importForm.url == '') {
        flag = 0; this.$message.error(`请选择excel文件并上传~`); return;

      }


      if (flag) {
        console.log(333, arr)
        this.$confirm(`是否确定导入实验室隐患情况？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {


          let loadingInstance = this.$loading({
            lock: true,
            text: "正在导入，请稍等....",
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.3)',
          })
          this.$http
            .post('/api/hidden_danger_lab_import_xls', {
              // term_id: this.importForm.term_id,
              url: this.importForm.url,
            })
            .then((res) => {
              if (res.data.errors && res.data.errors.length > 0) {
                let html = "";
                for (let msg of res.data.errors) {
                  html +=
                    "<div style='font-size:12px'>" + msg + "</div>";
                }
                this.$alert(html, "未导入数据", {
                  dangerouslyUseHTMLString: true,
                });
              } else {
                this.$message.success("导入成功");
                this.getList();
                this.showImportDialog = false;
              }
            });
          loadingInstance.close()
        })
      }
    },
  },
}
</script>

<style scoped="scoped">
.imgaddbtn {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dotted #ccc;
  font-size: 30px;
  cursor: pointer;
}

.imgremove {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;

  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -5px;
  color: #ccc;
}

.imgremove:hover {
  color: orange;
}
</style>
