<template>
	<div class="pageContainer cbox syssy">
		<div class="tabBox flexBetween">
			<div class="flexStart" style="padding-left:15px;">
				<el-breadcrumb>
					<el-breadcrumb-item>实验室管理</el-breadcrumb-item>
					<el-breadcrumb-item>实验室课程</el-breadcrumb-item>

				</el-breadcrumb>
			</div>
			<div class="tabBar flexEnd" style="margin-right: 10%">
				<div :class="['flexStart tabItem', activeTabIndex == 0 ? 'activeTab' : '']" @click="changeTab(0)">
					<i class="el-icon-notebook-2"></i><span style="margin-left: 5px;">实验室课程表</span>
				</div>
				<div :class="['flexStart tabItem', activeTabIndex == 1 ? 'activeTab' : '']" @click="changeTab(1)">
					<i class="el-icon-notebook-2"></i><span style="margin-left: 5px;">全部课程表</span>
				</div>
			</div>
		</div>
		<div v-show="activeTabIndex == 0" class="tabContent flexCloumn">
			<el-row class="flexStart" style="flex-wrap: wrap;">
				<!-- <span> 实验室：</span>

                <el-select v-model="sysID" filterable placeholder="请选择实验室" @change="chooseSys" size="small">
                    <el-option v-for="item in sysList" :key="item.id" :label="item.room_name" :value="item.id">
                    </el-option>
                </el-select> -->
				<span> 学期：</span>
				<el-select size="small" v-model="queryForm.term_id" filterable placeholder="学期" style=""
					@change="$forceUpdate()">
					<el-option v-for="(item, index) in termList" :key="index" :label="item.title"
						:value="item.id"></el-option>
				</el-select>
				<span class="ml10"> 周次：</span>
				<el-input class="ml10" v-model="queryForm.week" size="small" clearable placeholder="请输入本学期第几周"
					style="width:180px;"></el-input>

					

				<!-- <el-date-picker @change="changeDate" size="small" v-model="year_week" :picker-options="{
                    firstDayOfWeek: 1
                }" type="week" :format="showWeek" style="marign-right:50px"></el-date-picker> -->

				<el-button class="ml10" size="small" type="primary" icon="el-icon-search" style="display:inline-block"
					@click="queryCourseTable">查询</el-button>

				<el-button v-if="isAdmin" :type="canEdit ? 'warning' : 'primary'" size="small" icon="el-icon-edit"
					style="margin-left:10px" @click="canEdit = !canEdit">{{ canEdit ? '取消编辑' : '编辑课表' }}</el-button>

				 <el-button v-if="isAdmin && sysID"
                    type="danger"
                    size="small" icon="el-icon-delete" style="margin-left:10px"
                    @click="handleDelkcb"
         >删除课表</el-button>

				<!-- <el-button type="text" size="small" icon="el-icon-download" style="margin-left:10px">
                    <a href='./static/ImportTemps/课程表导入模板.xlsx' target="_blank"
                        style="text-decoration: none;">下载导入模板</a>
                </el-button>

                <el-button type="text" size="small" icon="el-icon-upload" style="margin-left:10px">
                    <a @click="uploadXls" target="_blank" style="text-decoration: none;">导入课程表</a>
                </el-button> -->

			</el-row>

			<div class="flex_1" style="width: 100%;height: calc(100% - 40px);">
				<div class="flexStretch" style="width: 100%;height: 100%;">
					<div class="dialog_left">
						<div class="bgGrey">

							<div class="flexStartUp" style="width:100%;height:50px;padding-left:15px">
								<el-select v-model="sysID" filterable placeholder="请选择实验室" @change="chooseSys"
									size="small" style="width:calc(100% - 21px );">
									<el-option v-for="item in sysList" :key="item.id" :label="`${item.address}-${item.room_name}`"
										:value="item.id">
									</el-option>
								</el-select>
							</div>
							<div class="listBox">
								<div :class="['listItem flexStart', activeIndex == index ? 'activeItem' : '']"
									v-for="(item, index) in sysList" @click="() => { getSchoolTimetable(item) }">
									<div class="dotted"></div>
									<div class="title flex_1"> {{ `${item.address}-${item.room_name}` }}</div>
									<i class="icon el-icon-right" v-if="activeIndex == index"></i>
								</div>
							</div>
						</div>
					</div>

					<div class="dialog_right">
						<div class="bcontent">

							<div v-if="sysID" style=" padding: 20px;width:100%;height: calc(100% - 0px);">

								<div style="text-align: center; padding-bottom: 20px;font-size: 20px;">{{ sysName }}
								</div>

								<table class="dttable">
									<thead style="width: 100%;">
										<tr style="width: 100%;">
											<td colspan="2" class="colTitle">星期/课时</td>
											<td class="colCell" :class="todayOfWeek == 1 ? 'bgLineHeight' : ''">星期一</td>
											<td class="colCell" :class="todayOfWeek == 2 ? 'bgLineHeight' : ''">星期二</td>
											<td class="colCell" :class="todayOfWeek == 3 ? 'bgLineHeight' : ''">星期三</td>
											<td class="colCell" :class="todayOfWeek == 4 ? 'bgLineHeight' : ''">星期四</td>
											<td class="colCell" :class="todayOfWeek == 5 ? 'bgLineHeight' : ''">星期五</td>
											<!-- <td>星期六</td>
                                            <td>星期日</td> -->
										</tr>
									</thead>
									<tbody style="width: 100%;">
										<tr v-for="(row, i) in ckSheet" :key="i" style="width: 100%;">
											<td :rowspan="row.rowspan" v-if="row.rowspan > 0" class="colTitleL">
												{{ row.timeanme }}
											</td>
											<td class="colTitleR">{{ row.keshi }}</td>
											<td class="colCell" :class="todayOfWeek == 1 ? 'bgLineHeight' : ''"
												@click="setCourse(i, 'week1')">
												{{ row.week1.content }}
												<span v-if="row.week1.course_name">
													{{ row.week1.course_name + "(" + row.week1.username + ")" }}
												</span>
												<i v-if="canEdit" class="el-icon-edit"></i>
											</td>
											<td class="colCell" :class="todayOfWeek == 2 ? 'bgLineHeight' : ''"
												@click="setCourse(i, 'week2')">
												{{ row.week2.content }}
												<span v-if="row.week2.course_name">
													{{ row.week2.course_name + "(" + row.week2.username + ")" }}
												</span>
												<i v-if="canEdit" class="el-icon-edit"></i>
											</td>
											<td class="colCell" :class="todayOfWeek == 3 ? 'bgLineHeight' : ''"
												@click="setCourse(i, 'week3')">
												{{ row.week3.content }}

												<span v-if="row.week3.course_name">
													{{ row.week3.course_name + "(" + row.week3.username + ")" }}
												</span>
												<i v-if="canEdit" class="el-icon-edit"></i>
											</td>
											<td class="colCell" :class="todayOfWeek == 4 ? 'bgLineHeight' : ''"
												@click="setCourse(i, 'week4')">
												{{ row.week4.content }}

												<span v-if="row.week4.course_name">
													{{ row.week4.course_name + "(" + row.week4.username + ")" }}
												</span>
												<i v-if="canEdit" class="el-icon-edit"></i>
											</td>
											<td class="colCell" :class="todayOfWeek == 5 ? 'bgLineHeight' : ''"
												@click="setCourse(i, 'week5')">
												{{ row.week5.content }}
												<span v-if="row.week5.course_name">
													{{ row.week5.course_name + "(" + row.week5.username + ")" }}
												</span>
												<i v-if="canEdit" class="el-icon-edit"></i>
											</td>
											<td v-if="false" @click="setCourse(i, 'week6')">
												{{ row.week1.content }}
												<span v-if="row.week6.course_name">
													{{ row.week6.course_name + "(" + row.week6.username + ")" }}
												</span>
												<i v-if="canEdit" class="el-icon-edit"></i>
											</td>
											<td v-if="false" @click=" setCourse(i, 'week7')">
												{{ row.week1.content }}
												<span v-if="row.week7.course_name">
													{{ row.week7.course_name + "(" + row.week7.username + ")" }}
												</span>
												<i v-if="canEdit" class="el-icon-edit"></i>
											</td>
										</tr>

									</tbody>
								</table>

								<div style="text-align: right; padding: 10px 0;">
									<div v-if="!registerList || registerList.length == 0" class="item"
										style="display: inline-block;">
										1.<el-button type="text" size="small">
											<a href='./static/ImportTemps/实训基地使用情况登记表.doc' target="_blank"
												style="text-decoration: none;">实训基地使用情况登记表</a>
										</el-button> <i class="el-icon-download"></i>
									</div>
									<div v-if="registerList && registerList.length > 0" class="item"
										style="display: inline-block;">
										1.<el-button type="text" size="small" @click="exportWord_register()">
											<a href='javascript:;' style="text-decoration: none;">{{ sysName
												}}使用情况登记表</a>
										</el-button> <i class="el-icon-download"></i>
									</div>
									<div class="item" style="display: inline-block; margin-left: 10px;">
										2.<el-button type="text" size="small">
											<a href='javascript:;' @click="export_syyxb"
												style="text-decoration: none;">{{sysName}}双月运行表</a>
										</el-button> <i class="el-icon-download"></i>
									</div>
									<div class="item" style="display: inline-block; margin-left: 10px;">
										3.<el-button type="text" size="small">
											<a href='./static/ImportTemps/实训基地使用率损坏维修统计表.xls' target="_blank"
												style="text-decoration: none;">实训基地使用率损坏维修统计表</a>
										</el-button> <i class="el-icon-download"></i>
									</div>
									<div class="item" style="display: inline-block; margin-left: 10px;">
										4.<el-button type="text" size="small">
											<a href='./static/ImportTemps/实训基地卫生检查记录表.xls' target="_blank"
												style="text-decoration: none;">实训基地卫生检查记录表</a>
										</el-button> <i class="el-icon-download"></i>
									</div>
									<div class="item" style="display: inline-block; margin-left: 10px;">
										5.<el-button type="text" size="small">
											<a href='./static/ImportTemps/教学实验实训开出项目计划与汇总表.doc' target="_blank"
												style="text-decoration: none;">实验室开出率</a>
										</el-button> <i class="el-icon-download"></i>
									</div>
								</div>

							</div>







						</div>
					</div>
				</div>


			</div>
		</div>
		<div v-show="activeTabIndex == 1" class="tabContent flexCloumn">
			<el-row class="flexStart" style="flex-wrap: wrap;">
				<span> 学期：</span>
				<el-select size="small" v-model="queryForm2.term_id" filterable placeholder="学期" style=""
					@change="$forceUpdate()">
					<el-option v-for="(item, index) in termList" :key="index" :label="item.title"
						:value="item.id"></el-option>
				</el-select>
				<span> 关键字：</span>
				<el-input class="ml10" v-model="queryForm2.keyword" size="small" clearable placeholder="请输入关键字"
					style="width:180px;"></el-input>

				<el-button class="ml10" size="small" type="primary" icon="el-icon-search" style="display:inline-block"
					@click="getAllData">查询</el-button>
        <el-button class="ml10" size="small" type="primary" icon="el-icon-download" style="display:inline-block"
                   @click="exportKcb">导出</el-button>
			</el-row>

			<div class="flex_1" style="width: 100%;height: calc(100% - 40px);">

				<el-table v-if="!allList || allList.length == 0" :data="[]" height="100%" stripe border size="small"
					empty-text="暂无课程安排" style="width:fit-content;width:100%;">
					<el-table-column type="aa" label="课程表" min-width="55" align="center"></el-table-column>
				</el-table>

				<div v-if="allList && allList.length > 0" class='cepContact'>
					<div class='form'>
						<el-row class='flexStretch'>
							<el-col :span='3' class='flexCenter cell'
								style='padding: 0 10px; border-left: 0;font-weight: bold;'>
								公共教室</el-col>
							<el-col :span='21'>
								<div style='width: 100%;'>
									<el-row>
										<el-col :span='2' class='flexCenter cell '
											style='padding: 0 10px;font-weight: bold;'>课时</el-col>
										<el-col :span='5' class='flexCenter cell'
											:class="todayOfWeek == 1 ? 'bgLineHeight' : ''"
											style='padding: 0 10px;font-weight: bold;'>星期一</el-col>
										<el-col :span='5' class='flexCenter cell '
											:class="todayOfWeek == 2 ? 'bgLineHeight' : ''"
											style='padding: 0 10px;font-weight: bold;'>星期二</el-col>
										<el-col :span='4' class='flexCenter cell '
											:class="todayOfWeek == 3 ? 'bgLineHeight' : ''"
											style='padding: 0 10px;font-weight: bold;'>星期三</el-col>
										<el-col :span='4' class='flexCenter cell '
											:class="todayOfWeek == 4 ? 'bgLineHeight' : ''"
											style='padding: 0 10px;font-weight: bold;'>星期四</el-col>
										<el-col :span='4' class='flexCenter cell '
											:class="todayOfWeek == 5 ? 'bgLineHeight' : ''"
											style='padding: 0 10px;font-weight: bold;'>星期五</el-col>
									</el-row>
								</div>
							</el-col>

						</el-row>
						<el-row class='flexStretch' v-for="(room, roomidx) in allList">
							<el-col :span='3' class='flexCenter cell cellMore' style='border-left: 0;padding:0 3px;'>
								{{ room.room_name }}</el-col>
							<el-col :span='21'>
								<div style='width: 100%;'>
									<el-row class="flexStretch" v-for="(keshi, ksIndex) in room.kcb">
										<el-col :span='2' class='flexCenter cell cellMore' style="padding:0 3px;">
											{{ keshi.keshi }} </el-col>
										<el-col :span='5' class='flexCenter cell cellMore'
											:class="todayOfWeek == 1 ? 'bgLineHeight' : ''" style="padding:0 3px;">
											{{ keshi.week1.kcap }}</el-col>
										<el-col :span='5' class='flexCenter cell cellMore'
											:class="todayOfWeek == 2 ? 'bgLineHeight' : ''" style="padding:0 3px;">
											{{ keshi.week2.kcap }}</el-col>
										<el-col :span='4' class='flexCenter cell cellMore'
											:class="todayOfWeek == 3 ? 'bgLineHeight' : ''" style="padding:0 3px;">
											{{ keshi.week3.kcap }}</el-col>
										<el-col :span='4' class='flexCenter cell cellMore'
											:class="todayOfWeek == 4 ? 'bgLineHeight' : ''" style="padding:0 3px;">
											{{ keshi.week4.kcap }}</el-col>
										<el-col :span='4' class='flexCenter cell cellMore'
											:class="todayOfWeek == 5 ? 'bgLineHeight' : ''" style="padding:0 3px;">
											{{ keshi.week5.kcap }}</el-col>
									</el-row>


								</div>
							</el-col>

						</el-row>




					</div>

				</div>






			</div>
		</div>


		<el-dialog title="编辑实验室课程" :visible.sync="dialogVisible" custom-class="width_800 cus_dialog"
			:close-on-click-modal="false">
			<el-form :model="EditItem" label-width="120px" ref="ruleForm">
				<el-row>
					<el-form-item label="学期" prop="title">
						<el-select size="small" disabled v-model="editForm.term_id" filterable placeholder="学期" style=""
							@change="$forceUpdate()">
							<el-option v-for="(item, index) in termList" :key="index" :label="item.title"
								:value="item.id"></el-option>
						</el-select>

					</el-form-item>



					<el-form-item label="当前实验室" prop="title">
						<el-select disabled v-model="sysID" filterable placeholder="请选择实验室" style="width:400px">
							<el-option v-for="item in sysList" :key="item.id" :label="item.room_name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>



					<el-form-item label="当前课时" prop="title">
						<div class=" flexStart">
							<div v-if="editForm.week">第{{ editForm.week }} 周</div>

							{{ TimeName }}
						</div>
					</el-form-item>

          <el-divider content-position="left">课程安排</el-divider>
					<div v-for="(course,index) in EditItem.courses" :key="index">
            <el-button size="small" type="primary" icon="el-icon-plus" circle style="margin-right: 10px;" @click="addItem(course)"></el-button>
            <el-popconfirm title="确定删除？" @confirm="removeItem(index)">
              <el-button slot="reference" size="small" type="danger" icon="el-icon-delete" circle></el-button>
            </el-popconfirm>
            <el-form-item label="选择课程" :prop="'courses.' + index + '.course_id'" :rules="{required: true, message: '请选择课程', trigger: 'change'}">
              <el-select v-model="course.course_id" filterable placeholder="请选择课程" style="width:400px" clearable
                         @change="chooseCourse">
                <el-option v-for="item in courseList" :key="item.id"
                           :label="item.course_name + (item.username ? '(' : '') + (item.username || '') + (item.username ? ')' : '') "
                           :value="item.course_id">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="选择授课老师" :prop="'courses.' + index + '.teacher_name'" :rules="{required: true, message: '请选择授课老师', trigger: 'focus'}">

              <el-input v-model="course.teacher_name" placeholder="请输入授课老师"
                        @focus="showDeptUserDialog(index)"></el-input>
            </el-form-item>
            <el-form-item label="授课班级" :prop="'courses.' + index + '.class_name'" :rules="{required: true, message: '请输入授课班级', trigger: 'blur'}">
              <el-input v-model="course.class_name" placeholder="请输入授课班级"></el-input>
            </el-form-item>

            <el-form-item label="是否应用到所有周次：" prop="title" label-width="200px">
              <el-switch v-model="course.is_all" active-text="是" inactive-text="否"></el-switch>
            </el-form-item>

            <el-form-item v-if="!course.is_all" label="上课周次" :prop="'courses.' + index + '.weekly'">
              <el-input class="ml10" v-model="course.weekly" size="small" clearable placeholder="请输入本学期上课周次"
                        style="width:100%;"></el-input>
              <div>连续的周次可以用'-'连接；例如上课周次是【第1周到第10周】：1-10</div>
              <div v-if="false">非连续的周次请直接填写数字，用','隔开；例如上课周次是【第1周到第3周，第6周，第8周到第10周】：1,2,3,6,8,9,10</div>
            </el-form-item>
            <el-divider />
          </div>
          <el-form-item :label="`是否同步${this.curr_ks % 2 === 0 ? '上节课' : '下节课'}`">
            <el-switch
                v-model="EditItem.is_sync" active-text="是" inactive-text="否" :active-value="1" :inactive-value="0"></el-switch>
          </el-form-item>




				</el-row>


			</el-form>

			<div slot="footer">
				<el-button v-if="EditItem.kcbIds" type="danger" icon="el-icon-delete" style="display:inline-block"
					@click="deleteCourseTable">删除这节课</el-button>
				<el-button type="primary" icon="el-icon-check" style="display:inline-block"
					@click="updateCourseTable">确认修改课表</el-button>
			</div>
		</el-dialog>


		<DeptUser :odata="[EditItem]" v-on:getUser="getUser" v-if="showUserDialog"></DeptUser>

	</div>
</template>

<script>
import DeptUser from '../../com/deptuser.vue'
import { exportWord } from "../../../utils/exportword";
import { ExportBriefDataDocx } from "@/utils/exportBriefDataDocx";
import util from "@/utils/util";

export default {
	components: {
		DeptUser
	},
	data() {
		return {
			temphead: "//school.mmteck.cn/static/temp.png",
			report_year: "",
			week: "",
			year_week: new Date(),
			ckSheet: [],
			EditItem: {
				courses: [],
			},
			keyword:"",
			dialogVisible: false,
			sysID: 0,
			sysName: "",
			sysList: [],
			courseID: "",
			courseList: [],
			TimeName: '',
			canEdit: false,
			ckdItem: {
				idx: -1,
				week: ""
			},
			activeTabIndex: 0,
			activeIndex: 0, //教室
			queryForm: {},
			queryForm2: {},
			termList: [],
			termIdInit: '',

			value: '',
			defaultWeek: this.getDefaultWeek(),

			allList: [],
			todayOfWeek: this.$moment().format('E'),
			editForm: {},
			showUserDialog: false,
			curr_ks: "",
			numOfks: ['第一节', '第二节', '第三节', '第四节', '第五节', '第六节', '第七节','第八节'],
			currentIdx: "",
			isAdmin: false,
			registerList: [],
			sysInfo: {},
		};
	},
	computed: {
		showWeek() {
			return `${this.report_year} 第 ${this.week} 周`
		},
	},
	mounted() {
		this.getTerm().then(_ => {
			this.getSysList()
			// this.changeTab(1)
			this.getAllData()
		})

			


		let ckSheet = []

		ckSheet.push({
			rowspan: 4,
			timeanme: "上午",
			keshi: "第一节",
			week1: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week2: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week3: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week4: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week5: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week6: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week7: {
				rowspan: 0,
				content: "",
				show: 1
			}
		})
		ckSheet.push({
			rowspan: 0,
			timeanme: "上午",
			keshi: "第二节",
			week1: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week2: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week3: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week4: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week5: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week6: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week7: {
				rowspan: 0,
				content: "",
				show: 1
			}
		})
		ckSheet.push({
			rowspan: 0,
			timeanme: "上午",
			keshi: "第三节",
			week1: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week2: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week3: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week4: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week5: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week6: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week7: {
				rowspan: 0,
				content: "",
				show: 1
			}
		})
		ckSheet.push({
			rowspan: 0,
			timeanme: "上午",
			keshi: "第四节",
			week1: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week2: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week3: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week4: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week5: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week6: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week7: {
				rowspan: 0,
				content: "",
				show: 1
			}
		})
		ckSheet.push({
			rowspan: 4,
			timeanme: "下午",
			keshi: "第五节",
			week1: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week2: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week3: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week4: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week5: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week6: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week7: {
				rowspan: 0,
				content: "",
				show: 1
			}
		})
		ckSheet.push({
			rowspan: 0,
			timeanme: "下午",
			keshi: "第六节",
			week1: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week2: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week3: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week4: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week5: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week6: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week7: {
				rowspan: 0,
				content: "",
				show: 1
			}
		})
		ckSheet.push({
			rowspan: 0,
			timeanme: "下午",
			keshi: "第七节",
			week1: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week2: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week3: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week4: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week5: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week6: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week7: {
				rowspan: 0,
				content: "",
				show: 1
			}
		})
		ckSheet.push({
			rowspan: 0,
			timeanme: "下午",
			keshi: "第八节",
			week1: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week2: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week3: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week4: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week5: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week6: {
				rowspan: 0,
				content: "",
				show: 1
			},
			week7: {
				rowspan: 0,
				content: "",
				show: 1
			}
		})

		this.ckSheet = ckSheet
		// this.changeDate()
		this.$http.post("/api/get_teacher_info", {

		}).then(res => {
			this.userinfo = res.data
		})

		this.getCourse()
		if (this.$route.query.roomId) {
			this.sysID = Number(this.$route.query.roomId)
		}
    setTimeout(() => {
      if (this.$store.state.teacher && this.$store.state.teacher.sys_roles.length > 0) {
        this.isAdmin = this.$store.state.teacher.sys_roles.filter(r => r.role_code.indexOf('admin') > -1||r.role_code=='sys').length > 0
      }
    },500)
	},
	methods: {
		getRegisterList() {
			this.$http.post("/api/sxjd_register_list", {
				pagesize: 90000000,
				term_id: this.queryForm.term_id,
				room_id: this.sysID
			}).then(res => {
				this.registerList = res.data.data;
				// console.log(840,this.registerList)
			})
		},
		getDefaultWeek() {
			const now = new Date();
			const start = new Date(now.getFullYear(), 0, 1);
			const dayOfWeek = start.getDay();
			const adjustedStart = start.getDate() + dayOfWeek * -1;

			const firstThursday = new Date(start.setDate(adjustedStart + 3));
			const firstWeek = new Date(+firstThursday);

			const time = firstWeek.getTime();
			const day = (now.getTime() - time + (now.getTimezoneOffset() * 60000)) % (7 * 24 * 60 * 60 * 1000);

			const currentWeekNumber = Math.floor(day / (7 * 24 * 60 * 60 * 1000)) + 1;

			return firstWeek.getFullYear() + '第' + currentWeekNumber + '周';
		},


		changeTab(idx) {
			this.activeTabIndex = idx
			// if (idx == 0) {
			//     this.getSysList()
			// } else {
			//     this.getAllData()
			// }
		},

		getTerm() {
			return new Promise(resolve => {
				this.$http.post(`/api/t_sch_terms_list`).then(res => {
					if (res && res.data.data && res.data.data.length > 0) {
						this.termList = res.data.data
						res.data.data && res.data.data.map(a => {
							if (this.globalCurrentDate > a.term_start && this
								.globalCurrentDate < a.term_end) {
								this.termIdInit = a.id
								this.queryForm.term_id = this.termIdInit
								this.queryForm2.term_id = this.termIdInit
							}
						})
						if (!this.termIdInit) {
							this.termIdInit = res.data.data[0].id
							this.queryForm.term_id = this.termIdInit
							this.queryForm2.term_id = this.termIdInit
						}
					}
					this.$forceUpdate()
					resolve()
				})
			})
		},
		getSysList() {
			this.$http.post("/api/syskcb_rooms", {
				pagesize: 2000,
				term_id: this.queryForm.term_id
			}).then(res => {
				this.sysList = res.data
				if (this.sysList.length) {
          if (this.sysID) {
            this.sysName = this.sysList.filter(s => s.id === this.sysID).map(s => s.room_name).pop()
            this.sysInfo = this.sysList.filter(s => s.id === this.sysID)[0];
            this.activeIndex = this.sysList.findIndex(s => s.id === this.sysID)
          } else {
            this.sysID = this.sysList[0].id
            this.sysName = this.sysList[0].room_name
            this.sysInfo = this.sysList[0]
          }
        }
				this.getData()
			})
		},
		getCourse() {
			this.$http.post("/api/t_sch_syskc_list", {
				pagesize: 200
			}).then(res => {
				let courseList = res.data

				this.courseList = this._lo.uniqBy(courseList, item => item.course_id)
				// console.log(863, res.data, this.courseList)
			})
		},
		getData() {
			//获取登记表
			this.registerList = []
			this.$forceUpdate()
			this.getRegisterList()

			let params = {}
			// if (this.activeTabIndex == 0) {
			params = Object.assign({
				room_id: this.sysID
			}, this.queryForm)
			// } else {
			//     params = {
			//         term_id: this.queryForm.term_id
			//     }
			// }
			//params.term_id = 99;

			this.$http.post("/api/syskcb_list", params).then(res2 => {
				this.editForm = {
					term_id: this.queryForm.term_id,
					week: this.queryForm.week,
				}
				let keshi8Arr = []
				if (res2.data && res2.data.length > 0) {
					let ckSheet = []
					ckSheet.push({
						rowspan: 4,
						timeanme: "上午",
						keshi: "第一节",
						week1: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week2: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week3: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week4: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week5: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week6: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week7: {
							rowspan: 0,
							content: "",
							show: 1
						}
					})
					ckSheet.push({
						rowspan: 0,
						timeanme: "上午",
						keshi: "第二节",
						week1: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week2: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week3: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week4: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week5: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week6: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week7: {
							rowspan: 0,
							content: "",
							show: 1
						}
					})
					ckSheet.push({
						rowspan: 0,
						timeanme: "上午",
						keshi: "第三节",
						week1: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week2: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week3: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week4: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week5: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week6: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week7: {
							rowspan: 0,
							content: "",
							show: 1
						}
					})
					ckSheet.push({
						rowspan: 0,
						timeanme: "上午",
						keshi: "第四节",
						week1: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week2: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week3: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week4: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week5: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week6: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week7: {
							rowspan: 0,
							content: "",
							show: 1
						}
					})
					ckSheet.push({
						rowspan: 4,
						timeanme: "下午",
						keshi: "第五节",
						week1: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week2: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week3: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week4: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week5: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week6: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week7: {
							rowspan: 0,
							content: "",
							show: 1
						}
					})
					ckSheet.push({
						rowspan: 0,
						timeanme: "下午",
						keshi: "第六节",
						week1: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week2: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week3: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week4: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week5: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week6: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week7: {
							rowspan: 0,
							content: "",
							show: 1
						}
					})
					ckSheet.push({
						rowspan: 0,
						timeanme: "下午",
						keshi: "第七节",
						week1: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week2: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week3: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week4: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week5: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week6: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week7: {
							rowspan: 0,
							content: "",
							show: 1
						}
					})
					ckSheet.push({
						rowspan: 0,
						timeanme: "下午",
						keshi: "第八节",
						week1: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week2: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week3: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week4: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week5: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week6: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week7: {
							rowspan: 0,
							content: "",
							show: 1
						}
					})
					res2.data.map(a => {
						if (a.kcb) {
							a.kcb.map(k => {
								// console.log(k)
								if (k.keshi && k.keshi.indexOf(',')) {
									let prek = k.keshi
									let kArr = prek.split(',')
									k.kArr = kArr
								}
							})
						}

					})
					res2.data.map(a => {
						if (a.kcb) {
							a.kcb.map(k => {
								if (k.kArr) {
									k.kArr.map(j => {
										let obj = {
											...k
										}
										obj.jie = Number(j)
										keshi8Arr.push(obj)
									})
								}
							})
						}

					})

					//这里改了lc
					// console.log(424, keshi8Arr)
					for (let i = 0; i < keshi8Arr.length; i++) {
						let kc = keshi8Arr[i]
						let jieci = ckSheet[kc.jie - 1]
						// console.log(399, i, kc, 2, jieci)
						/*jieci['week1'].content = kc.week1.kcap
						jieci['week2'].content = kc.week2.kcap
						jieci['week3'].content = kc.week3.kcap
						jieci['week4'].content = kc.week4.kcap
						jieci['week5'].content = kc.week5.kcap*/
						this.getWeekCourse(kc, jieci)
						// jieci['week' + kc.weekday].course_name = kc.course_name
						// jieci['week' + kc.weekday].courseID = kc.course_id
						// jieci['week' + kc.weekday].username = kc.teacher_name
						// for (let k in kc) {
						//     jieci['week' + kc.weekday][k] = kc[k]
						// }

					}

					// console.log(443, this.todayOfWeek, ckSheet)
					this.ckSheet = ckSheet

				} else {
					let ckSheet = []
					ckSheet.push({
						rowspan: 4,
						timeanme: "上午",
						keshi: "第一节",
						week1: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week2: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week3: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week4: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week5: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week6: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week7: {
							rowspan: 0,
							content: "",
							show: 1
						}
					})
					ckSheet.push({
						rowspan: 0,
						timeanme: "上午",
						keshi: "第二节",
						week1: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week2: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week3: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week4: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week5: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week6: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week7: {
							rowspan: 0,
							content: "",
							show: 1
						}
					})
					ckSheet.push({
						rowspan: 0,
						timeanme: "上午",
						keshi: "第三节",
						week1: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week2: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week3: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week4: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week5: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week6: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week7: {
							rowspan: 0,
							content: "",
							show: 1
						}
					})
					ckSheet.push({
						rowspan: 0,
						timeanme: "上午",
						keshi: "第四节",
						week1: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week2: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week3: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week4: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week5: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week6: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week7: {
							rowspan: 0,
							content: "",
							show: 1
						}
					})
					ckSheet.push({
						rowspan: 4,
						timeanme: "下午",
						keshi: "第五节",
						week1: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week2: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week3: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week4: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week5: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week6: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week7: {
							rowspan: 0,
							content: "",
							show: 1
						}
					})
					ckSheet.push({
						rowspan: 0,
						timeanme: "下午",
						keshi: "第六节",
						week1: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week2: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week3: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week4: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week5: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week6: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week7: {
							rowspan: 0,
							content: "",
							show: 1
						}
					})
					ckSheet.push({
						rowspan: 0,
						timeanme: "下午",
						keshi: "第七节",
						week1: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week2: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week3: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week4: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week5: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week6: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week7: {
							rowspan: 0,
							content: "",
							show: 1
						}
					})
					ckSheet.push({
						rowspan: 0,
						timeanme: "下午",
						keshi: "第八节",
						week1: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week2: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week3: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week4: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week5: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week6: {
							rowspan: 0,
							content: "",
							show: 1
						},
						week7: {
							rowspan: 0,
							content: "",
							show: 1
						}
					})
					//这里改了lc
          for (let sheet of ckSheet) {
            sheet.jie = this.numOfks.indexOf(sheet.keshi) + 1
            this.getWeekCourse(sheet,sheet)
          }
					this.ckSheet = ckSheet
				}
			})
		},
		getAllData() {
			let params = {}
			params = {
				term_id: this.queryForm2.term_id,
				keyword: this.queryForm2.keyword,
			}
			this.$http.post("/api/syskcb_list", params).then(res2 => {

				// console.log(res2.data)
				this.allList = res2.data
			})
		},
		chooseSys() {
			this.sysList.map((a, ia) => {
				if (this.sysID == a.id) {
					this.sysInfo = a
					this.sysID = a.id
					this.sysName = a.room_name
					this.activeIndex = ia
					this.getData()
					this.jump(ia)
					return
				}
			})

		},
		getSchoolTimetable(row) {
			this.sysInfo = row
			this.sysID = row.id
			this.sysName = row.room_name
			this.sysList.map((a, ia) => {
				if (a.id == row.id) {
					this.activeIndex = ia
					return
				}
			})
			this.getData()
		},

		changeDate(e) {

			let str = this.$moment(this.year_week).utcOffset(480).format("yyyy-WW")
			this.weekStart = this.$moment(this.year_week).utcOffset(480).format("yyyy-MM-DD")
			this.weekEnd = this.$moment(this.year_week).add(6, "day").utcOffset(480).format("yyyy-MM-DD")

			let arr = str.split('-')
			this.report_year = arr[0]
			this.week = arr[1]

			this.getData()

		},
		setCourse(i, week) {
			if (!this.canEdit) {
				return
			}
			switch (week) {
				case "week1":
					this.TimeName = '星期一'
					break
				case "week2":
					this.TimeName = '星期二'
					break
				case "week3":
					this.TimeName = '星期三'
					break
				case "week4":
					this.TimeName = '星期四'
					break
				case "week5":
					this.TimeName = '星期五'
					break
				case "week6":
					this.TimeName = '星期六'
					break
				case "week7":
					this.TimeName = '星期日'
					break

			}
			this.TimeName += " " + this.ckSheet[i].keshi + "课"

			this.TimeName = `${this.report_year} 第 ${this.week} 周 ` + this.TimeName
			this.EditItem = JSON.parse(JSON.stringify(this.ckSheet[i][week]))
			this.ckdItem.idx = i
			this.ckdItem.week = week
			this.dialogVisible = true
      this.curr_ks = this.numOfks.indexOf(this.ckSheet[i].keshi) + 1
      this.currentIdx = ""
			// console.log(this.EditItem,this.curr_ks)


		},
		chooseCourse() {
			let editItem = this.ckSheet[this.ckdItem.idx][this.ckdItem.week]
			if (!this.courseID) {
				this.EditItem.courseID = "";
				this.EditItem.course_name = ""
				this.EditItem.username = ""
				editItem.courseID = "";
				editItem.course_name = "";
				editItem.username = "";
				editItem.courseID = 0;
				editItem.course_id = 0;
				// console.log(editItem)
				// console.log(this.ckSheet[this.ckdItem.idx])

				// this.$http.post("/api/syskcb_save_one", editItem).then(res => {

				//         })

			} else {
				this.EditItem.courseID = this.courseID
				for (let item of this.courseList) {
					if (item.id == this.courseID) {
						this.EditItem.course_name = item.course_name
						this.EditItem.username = item.username
						this.EditItem.courseID = this.courseID
						editItem.course_name = item.course_name
						editItem.username = item.username
						editItem.courseID = this.courseID
						this.ckSheet[this.ckdItem.idx][this.ckdItem.week] = editItem

						if (!this.ckSheet[this.ckdItem.idx][this.ckdItem.week].id) {
							// console.log(this.ckSheet[this.ckdItem.idx])
							// console.log(this.ckdItem.week)

							let formData = {
								year: this.report_year,
								week: this.week,
								sdate: this.weekStart,
								edate: this.weekEnd,
								json_content: this.ckSheet,
								roomid: this.sysID,
								jc: this.ckdItem.idx + 1,
								teacher_id: item.teacher_id,
								teacher_name: item.username,
								weekday: this.ckdItem.week
							}
							// console.log(item)
							formData = {
								...formData,
								...editItem
							}
							// this.$http.post("/api/syskcb_save_one", formData).then(res => {

							// })
							// console.log(1728, formData)
						} else {

							// this.$http.post("/api/syskcb_save_one", editItem).then(res => {

							// })
						}
						break
					}
				}
			}

		},


		getUser(e) {
			console.log('----------------')
			console.log(e)
			if (e && e.length > 0) {
				this.EditItem.teacher_id = e[0].id
				this.EditItem.username = e[0].username
				if (this.currentIdx === 0 || this.currentIdx > 0) {
					this.EditItem.courses[this.currentIdx].teacher_id = e[0].id
					this.EditItem.courses[this.currentIdx].teacher_name = e[0].username
				}
			}
			this.showUserDialog = false
		},
		showDeptUserDialog(idx) {
			//alert(111)
			this.currentIdx = idx
			this.showUserDialog = true
		},
		deleteItem(e) {
			this.$confirm('是否确认删除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.post('/api/t_sch_syskc_delete', { id: e.id }).then(res => {
					this.$message.success('删除成功');
					this.getList()
				})
			})
		},
		saveForm() {

			let formData = {
				year: this.report_year,
				week: this.week,
				sdate: this.weekStart,
				edate: this.weekEnd,
				json_content: this.ckSheet,
				roomid: this.sysID
			}
			this.canEdit = false

			// console.log(formData)
			// this.$http.post("/api/syskcb_save", formData).then(res => {
			//     this.$message.success("保存成功")
			// })
			this.$message.success("保存成功")
		},


		jump(index) {

			let target = document.querySelector('.listBox')

			let scrollItems = document.querySelectorAll('.listItem')

			// 判断滚动条是否滚动到底部

			if (target.scrollHeight <= target.scrollTop + target.clientHeight) {

				this.activeStep = index

			}

			let total = scrollItems[index].offsetTop - scrollItems[0]
				.offsetTop // 锚点元素距离其offsetParent(这里是body)顶部的距离(待滚动的距离)

			let distance = document.querySelector('.listBox').scrollTop // 滚动条距离滚动区域顶部的距离

			// let distance = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset // 滚动条距离滚动区域顶部的距离(滚动区域为窗口)

			// 滚动动画实现, 使用setTimeout的递归实现平滑滚动，将距离细分为50小段，10ms滚动一次

			// 计算每一小段的距离

			let step = total / 50

			if (total > distance) {

				smoothDown(document.querySelector('.listBox'))

			} else {

				let newTotal = distance - total

				step = newTotal / 50

				smoothUp(document.querySelector('.listBox'))

			}

			// 参数element为滚动区域

			function smoothDown(element) {

				if (distance < total) {

					distance += step

					element.scrollTop = distance

					setTimeout(smoothDown.bind(this, element), 10)

				} else {

					element.scrollTop = total

				}

			}

			// 参数element为滚动区域

			function smoothUp(element) {

				if (distance > total) {

					distance -= step

					element.scrollTop = distance

					setTimeout(smoothUp.bind(this, element), 10)

				} else {

					element.scrollTop = total

				}

			}
		},

		uploadXls() {
			document.getElementById("addXlsbtn").click()
		},

		uploadAttch(e) {
			let _this = this
			this.$http.post("/api/syskcb_import_xls", {
				url: e.src
			}).then(res => {
				let error = res.data.error
				if (error.length) {
					let _html =
						"<div style='max-height: 600px;line-height:28px;text-align:center;overflow-y: auto'>"
					for (let value of error) {
						_html += `<p style="line-height: 30px;">${value}</p>`
					}
					_html += '</div>'
					this.$alert(_html, '导入失败数据', {
						dangerouslyUseHTMLString: true,
					})
				} else {
					this.$message.success("导入成功");
				}
				// this.getData();



			})
		},
		getWeekCourse(kc, jieci) {
			for (let i = 1; i <= 5; i++) {
				let key = `week${i}`
				jieci[key].content = ""
				jieci[key].courses = []
				if (kc[key].courses && kc[key].courses.length > 0) {
					let courses = kc[key].courses
					courses.map(c => {
						c.is_all = !c.weekly;
					})
					let currentCourses = courses.filter(c => c.jie === kc.jie)
					for (let cur of currentCourses) {
						jieci[key].content += `${cur.course_name}.${cur.teacher_name}` + (cur.weekly ? ":" + `${cur.weekly}.` : ".") + cur.class_name + '.'
					}
					jieci[key].content = jieci[key].content.substring(0, jieci[key].content.length - 1)
					jieci[key].courses = courses.filter(c => c.jie === kc.jie)
					jieci[key].prev_next_ids = courses.filter(c => c.jie !== kc.jie).map(c => c.id).join(',')
					jieci[key].kcbIds = courses.filter(c => c.jie === kc.jie).map(c => c.id).join(',')
				}
				if (!jieci[key].courses.length) {
					jieci[key].courses.push({
						term_id: this.queryForm.term_id,
						roomid: this.sysID,
						course_id: "",
						teacher_name: "",
						class_name: "",
						is_all: false,
						weekly: "",
						weekday: i,
						jie: kc.jie
					})
				}
			}
			return jieci
		},
		updateCourseTable() {
			this.$refs.ruleForm.validate(valid => {
				if (valid) {
					let formData = { ...this.EditItem }
					formData.courses.map(c => {
						if (c.is_all) {
							c.weekly = null
						}
					})
					formData.courses = JSON.stringify(formData.courses)
					this.$http.post("/api/syskcb_save", formData).then(res => {
						this.$message.success("保存成功");
						this.dialogVisible = false
						this.getSysList()
					})
				}
			})
		},
		deleteCourseTable() {
			this.$confirm('是否确认删除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.post("/api/syskcb_delete", { ids: this.EditItem.kcbIds }).then(res => {
					this.$message.success("删除成功");
					this.dialogVisible = false
					this.getSysList()
				})
			})
		},
		addItem(row) {
			this.EditItem.courses.push({
				term_id: row.term_id,
				roomid: row.roomid,
				course_id: "",
				teacher_name: "",
				class_name: "",
				is_all: false,
				weekly: "",
				weekday: row.weekday,
				jie: row.jie
			})
		},
		removeItem(idx) {
			if (this.EditItem.courses.length < 2) {
				this.$message.error("至少保留一条数据");
				return;
			}
			this.EditItem.courses.splice(idx, 1)
		},
		async exportWord_register() {
			let index = 0; let list = []; let term_name = ''
			for (let item of this.registerList) {
				index += 1
				item.index = index
				item.class_name = item.class_name || item.class_id
				// item.handsign = item.handsign || ''
				//item.handsign = ''
        if (item.handsign) {
          item.handsign = await util.getBase64Sync(item.handsign)
        }
				list.push(item)
			}
			this.termList.map((a, ia) => {
				console.log(2222, a, this.queryForm.term_id)
				if (a.id == this.queryForm.term_id) {
					term_name = a.title
					return
				}
			})
			let info = this.sysInfo
			info.list = list
			info.term_name = term_name
			info.dept_name = info.dept_name || ''
			setTimeout(() => {
				console.log(2071, info)
        let imgSize = {
          handsign: [45,45],
          centered: false,
        }
				// exportWord('./static/ImportTemps/实训基地使用情况登记表[导出数据模板].doc', info, `${this.sysName}使用情况登记表.doc`)
				ExportBriefDataDocx('/static/ImportTemps/实训基地使用情况登记表[导出数据模板].docx', info, `${this.sysName}使用情况登记表.docx`,imgSize) // text.docx放在了根目录下的public文件夹下

			}, 200);

		},
    handleDelkcb() {
      this.$confirm('此操作不可恢复，确认删除当前实验室全部课程？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post("/api/syskcb_delete_all", {
          room_id: this.sysID,
          term_id: this.queryForm.term_id
        }).then(res => {
          this.$message.success("删除成功");
          this.getSysList()
        })
      })
    },
    queryCourseTable() {
      this.sysID = ""
      this.getSysList()
    },
    export_syyxb() {
      let param = {...this.queryForm}
      param.room_id = this.sysID
      this.$http.post('/api/export_syyxb', param).then(res => {
        window.location.href = res.data.url
      })
    },
    exportKcb() {
      this.$http.post('/api/syskcb_export',{term_id:this.queryForm2.term_id}).then(res => {
        window.open(res.data.url,'_blank')
      })
    },
	},
}
</script>
<style lang="less" type="text/less">
.syssy {
	.el-input.is-disabled .el-input__inner {
		color: #606266;
	}
}

.cepContact .el-col {
	display: flex;
	padding: 0;
	margin: 0;
}

.cepContact .el-row {
	width: 100%;
	padding: 0;
	margin: 0;
}
</style>
<style scoped="scoped" lang="less" type="text/less">
.bgLineHeight {
	background-color: rgba(24, 144, 255, 0.08);
}

.cepContact {
	margin-top: 25px;
	width: 100%;
	height: calc(100% - 25px);
	overflow-y: auto;


	.title {
		font-size: 20px;
		line-height: 50px;
	}

	.form {
		width: 100% - 0px;
		border: 1px solid #c0d2ff;
		border-top: 0;

		div {
			font-size: 14px;
			color: #323232;
			box-sizing: border-box;
		}

		.content {
			width: 100%;
			height: 100%;
			padding: 10px;
			white-space: break-spaces;
			word-break: break-all;
			overflow: hidden;
		}

	}

	.col-row {
		width: 100%;
		margin: 0;
		padding: 0;
	}


	.table {
		width: 100%;
	}

	.borderR {
		border-right: 1px solid #c0d2ff;
	}

	.borderB {
		border-bottom: 1px solid #c0d2ff;
	}



	.cell {
		border-left: 1px solid #c0d2ff;
		border-top: 1px solid #c0d2ff;

		min-height: 30px;
		//line-height: 30px;
		//text-align: center;
		white-space: nowrap;
		font-size: 14px;
		word-break: break-all;
		white-space: normal;
	}

	.cell50 {
		min-height: 50px;
		line-height: 50px;
		font-size: 14px;
	}

	.cellMore {
		border-left: 1px solid #c0d2ff;
		border-top: 1px solid #c0d2ff;
		min-height: 50px;
		text-align: left;
		font-size: 14px;
	}


}



.dttable {
	/*table-layout: fixed;*/

	td {
		text-align: center;
		word-wrap: break-word;
		width: auto !important;
	}

	tbody td {
		/*cursor: pointer;*/
	}

}

.cbox {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	padding: 0;

	div {
		box-sizing: border-box;

	}

	.el-table__empty-block {
		width: 100% !important;
	}

	.tabBox {
		width: 100%;
		height: 40px;
		border-bottom: 1px solid #98b9fc;

		.tabBar {
			height: 100%;
			align-items: end;

			.tabItem {
				height: 30px;
				border: 1px solid #3f4bda;
				margin-left: 15px;
				padding: 0 5px;
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
				margin-bottom: -1px;
				cursor: pointer;
			}

			.activeTab {
				background-color: #2469f3;
				border: 0;
				color: #fff;
			}

			.activeTabTgt {
				background-color: #3e4ddb;
				border: 0;
				color: #fff;
			}
		}
	}

	.tabContent {
		padding: 20px 15px;
		height: calc(100% - 40px);

		.dialog_left {
			width: 300px;
			height: 100%;
			overflow: hidden;

			.bgGrey {
				margin-top: 20px;
				padding: 20px 0;
				height: calc(100% - 20px);
				border-radius: 15px;
				background-color: rgba(24, 144, 255, 0.08);

				.listBox {
					height: 100%;
					padding: 0 15px;
					overflow-y: auto;
					height: calc(100% - 50px);

					.listItem {
						margin-bottom: 20px;
						cursor: pointer;

						.dotted {
							margin-right: 10px;
							width: 10px;
							height: 10px;
							border-radius: 50%;
							background-color: #7a7a7a;
						}

						.title {
							color: #7a7a7a;
							font-weight: 700;
						}

						.icon {
							margin-left: 10px;
							color: #3073f7;
							font-weight: 700;
						}
					}

					.activeItem {
						.dotted {
							background-color: #3073f7;
						}

						.title {
							color: #3073f7;
						}
					}
				}
			}
		}

		.dialog_right {
			margin-left: 30px;
			width: calc(100% - 330px);
			height: 100%;
			overflow: hidden;

			.bcontent {
				width: 100%;
				height: 100%;
			}

			.formBox {
				width: 100%;
				height: calc(100% - 50px);
				margin-top: 30px;

				.tabBox {
					width: 100%;
					height: 40px;
					border-bottom: 1px solid #98b9fc;
					align-items: end;

					.tabBar {
						height: 100%;
						align-items: end;
						padding-left: 40px;

						.tabItem {
							height: 30px;
							border: 1px solid #3f4bda;
							margin-right: 15px;
							padding: 0 5px;
							border-top-left-radius: 8px;
							border-top-right-radius: 8px;
							margin-bottom: -1px;
							cursor: pointer;
							font-size: 14px;
							white-space: nowrap;
						}

						.activeTab {
							background-color: #2469f3;
							border: 0;
							color: #fff;
						}

						.activeTabTgt {
							background-color: #3e4ddb;
							border: 0;
							color: #fff;
						}
					}
				}

				.tabContent {
					width: 100%;
					height: calc(100% - 100px);
					padding-left: 40px;
				}
			}

			.targetItem {
				margin-bottom: 10px;
				width: 100%;
			}
		}
	}
}
</style>