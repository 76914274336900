<template>
  <div class="pageContainer cbox syssy">
    <div class="tabBox flexBetween">
      <div class="flexStart" style="padding-left: 15px">
        <el-breadcrumb>
          <el-breadcrumb-item>实验室管理</el-breadcrumb-item>
          <el-breadcrumb-item>实验室使用情况登记表</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="tabBar flexEnd" style="margin-right: 10%">
        <div :class="['flexStart tabItem', activeTabIndex == 0 ? 'activeTab' : '']"><i
            class="el-icon-notebook-2"></i><span style="margin-left: 5px">实验室使用情况登记表</span></div>
      </div>
    </div>
    <div class="tabContent flexCloumn">
      <el-row class="flexBetween" style="flex-wrap: wrap; width: 100%">
        <div class="flex_1 felxStart">
          <!-- <span> 实验室：</span>

                <el-select v-model="sysID" filterable placeholder="请选择实验室" @change="chooseSys" size="small">
                    <el-option v-for="item in sysList" :key="item.id" :label="item.room_name" :value="item.id">
                    </el-option>
                </el-select> -->
          <span> 学期：</span>
          <el-select size="small" v-model="queryForm.term_id" filterable placeholder="学期" style=""
            @change="$forceUpdate()">
            <el-option v-for="(item, index) in termList" :key="index" :label="item.title" :value="item.id"></el-option>
          </el-select>
          <span class="ml10"> 周次：</span>
          <el-input class="ml10" v-model="queryForm.week" size="small" clearable placeholder="请输入本学期第几周"
            style="width: 180px"></el-input>

          <!-- <el-date-picker @change="changeDate" size="small" v-model="year_week" :picker-options="{
                    firstDayOfWeek: 1
                }" type="week" :format="showWeek" style="marign-right:50px"></el-date-picker> -->

          <el-button class="ml10" size="small" type="primary" icon="el-icon-search" style="display: inline-block"
            @click="queryCourseTable">查询</el-button>

          <el-button class="ml10" size="small" type="primary" icon="el-icon-plus" style="display: inline-block"
            @click="addNew">新增</el-button>



        </div>
        <div class="flexStart">
          <el-button size="small" type="primary" plain style="display:inline-block;margin-right:20px;"
            icon="el-icon-upload" @click="showImportDialog = true">导入
          </el-button>

          <div v-if="!registerList || registerList.length == 0" class="item" style="display: inline-block">
            <el-button type="text" size="small">
              <a href="./static/ImportTemps/实训基地使用情况登记表.doc" target="_blank"
                style="text-decoration: none">实训基地使用情况登记表</a>
            </el-button>
            <i class="el-icon-download"></i>
          </div>
          <div v-if="registerList && registerList.length > 0" class="item" style="display: inline-block">
            <el-button type="text" size="small" @click="exportWord_register()">
              <a href="javascript:;" style="text-decoration: none">{{ sysName }}使用情况登记表</a>
            </el-button>
            <i class="el-icon-download"></i>
          </div>
        </div>
      </el-row>

      <div class="flex_1" style="width: 100%; height: calc(100% - 40px)">
        <div class="flexStretch" style="width: 100%; height: 100%">
          <div class="dialog_left">
            <div class="bgGrey">
              <div class="flexStartUp" style="width: 100%; height: 50px; padding-left: 15px">
                <el-select v-model="sysID" filterable placeholder="请选择实验室" @change="chooseSys" size="small"
                  style="width: calc(100% - 21px)">
                  <el-option v-for="item in sysList" :key="item.id" :label="`${item.address}-${item.room_name}`"
                    :value="item.id"> </el-option>
                </el-select>
              </div>
              <div class="listBox">
                <div :class="['listItem flexStart', activeIndex == index ? 'activeItem' : '']"
                  v-for="(item, index) in sysList" :key="index" @click="
                    () => {
                      getSchoolTimetable(item);
                    }
                  ">
                  <div class="dotted"></div>
                  <div class="title flex_1">{{ `${item.address}-${item.room_name}` }}</div>
                  <i class="icon el-icon-right" v-if="activeIndex == index"></i>
                </div>
              </div>
            </div>
          </div>

          <div class="dialog_right">
            <div class="bcontent">
              <div class="flexCloumn" style="margin-top: 20px; width: 100%; height: calc(100% - 20px)">
                <div class="flexCenter" style="width: 100%; text-align: center; padding-bottom: 20px;font-size: 20px;">
                  {{
                  sysName }}
                </div>
                <el-table :data="registerList" height="100%" stripe border size="small"
                  style="width: fit-content; width: 100%">
                  <el-table-column type="index" label="编号" width="55" align="center"></el-table-column>
                  <el-table-column prop="weekly" label="周次" width="60" align="center"></el-table-column>
                  <el-table-column prop="register_date" label="日期" width="120" align="center">
                    <template slot-scope="scope">
                      <span style="font-size: 14px;">{{ scope.row.register_date }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="class_id" label="班级" width="120" align="center"></el-table-column>
                  <el-table-column prop="course_name" label="课程" min-width="120" show-overflow-tooltip
                    align="center"></el-table-column>
                  <el-table-column prop="project_desc" label="实验、实训项目" width="200" align="center">
                    <template slot-scope="scope">
                      <span v-if="scope.row.project_desc && scope.row.project_desc.length > 10">
                        <el-popover placement="bottom-start" title="" trigger="hover" popper-class="workorder-remark">
                          <div>{{ scope.row.project_desc }}</div>
                          <span slot="reference">
                            {{ scope.row.project_desc.substr(0,10) + '...' }}
                          </span>
                        </el-popover>
                      </span>
                      <span v-else>{{ scope.row.project_desc }}</span>
                    </template>

                  </el-table-column>
                  <el-table-column prop="test_num" label="实验人数" width="60" align="center"></el-table-column>
                  <el-table-column prop="group_num" label="每组人数" width="60" align="center"></el-table-column>
                  <el-table-column prop="class_hour" label="学时" width="60" align="center"></el-table-column>
                  <el-table-column prop="teacher_nam" label="指导教师" width="180" align="center"></el-table-column>
                  <el-table-column prop="handsign" label="签名" width="120" align="center">
                    <template slot-scope="scope">
                      <img style="width: 90px;" :src="scope.row.handsign" alt="">
                    </template>
                  </el-table-column>

                  <el-table-column label="操作" width="130" fixed="right" align="center">
                    <template slot-scope="scope">
                      <el-tag size="mini" type="danger" style="cursor: pointer" @click="deleteItem(scope.row)"><i
                          class="el-icon-delete"></i>删除</el-tag>

                      <el-tag v-if="userinfo.id==scope.row.teacher_id" size="mini" type="success"
                        style="margin-left: 5px; cursor: pointer" @click="editRow(scope.row)"><i
                          class="el-icon-delete"></i>编辑</el-tag>
                    </template>
                  </el-table-column>
                </el-table>

                <el-pagination class="cus_page" :page-sizes="[10, 20, 50, 100]" :page-size="page.per_page"
                  layout="total" :total="page.count" style="margin-top: 10px"> </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    <el-dialog title="实验室课程登记" :visible.sync="dialogVisible" custom-class="width_800 cus_dialog"
      :close-on-click-modal="false" @close="onClose">
      <el-form :model="EditItem" label-width="120px">
        <el-row>
          <el-col :span="11">
            <el-form-item label="学期" required>
              <el-select v-model="termIdInit">
                <el-option :label="item.title" :value="item.id" v-for="(item, i) in termList" :key="i"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="周次" required>

              <el-select v-model="week">
                <el-option :label="item.title" :value="i" v-for="(item, i) in weekList" :key="i"></el-option>
              </el-select>

            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="日期" required>
              <el-date-picker v-model="EditItem.register_date" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
              </el-date-picker>


            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="班级" required>

              <el-select v-model="EditItem.class_id" filterable @change="(e) => dynamicFillVal(e,'class')">
                <el-option :label="item" :value="item" v-for="(item, i) in classList" :key="i"></el-option>
              </el-select>

            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="课程" required>

              <el-select v-model="EditItem.course_id" filterable @change="(e) => dynamicFillVal(e,'course')">
                <el-option :label="item.course_name" :value="item.course_id" v-for="(item, i) in courseList"
                  :key="i"></el-option>
              </el-select>

            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="实验室" required>
              <el-select v-model="EditItem.room_id" filterable @change="(e) => dynamicFillVal(e,'room')">
                <el-option :label="item.room_name" :value="item.roomid" v-for="(item, i) in sysList"
                  :key="i"></el-option>
              </el-select>

            </el-form-item>
          </el-col>

          <el-col :span="22">
            <el-form-item label="实验、实训项目" required label-width="150px">
              <el-input type="textarea" v-model="EditItem.project_desc" placeholder="请输入实验、实训项目"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="实验人数">
              <el-input v-model="EditItem.test_num" placeholder="请输入实验人数"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="每组人数">
              <el-input v-model="EditItem.group_num" placeholder="请输入每组人数"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="学时">
              <el-input v-model="EditItem.class_hour" placeholder="请输入学时"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="指导老师">
              <el-input v-model="userinfo.username" disabled placeholder="请输入指导老师"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="电子签名">
              <img :src="userinfo.handsign" style="width: 100px;" />
            </el-form-item>
          </el-col>

          <el-col :span="22">
            <el-form-item label="备注">
              <el-input type="textarea" v-model="EditItem.remark" placeholder="请输入备注"></el-input>
            </el-form-item>
          </el-col>


        </el-row>


        <el-row style="text-align:right">
          <el-col :span="20">
            <el-button type="primary" icon="el-icon-check" style="display:inline-block"
              @click="saveSysCourse">提交</el-button>
          </el-col>

        </el-row>

      </el-form>

    </el-dialog>


    <el-dialog :visible.sync="showImportDialog" :close-on-click-modal="false" :destroy-on-close="true"
      custom-class="cus_dialog cus_dialogMin" width="600px">
      <div slot="title" class="flexBetween">
        <span style="color: #ffffff;">导入实验室使用情况登记表</span>
      </div>

      <el-form :model="importForm" size="small" label-width="110px" style="overflow-y: auto;" label-position="left">

        <el-form-item label="下载模板" class="">
          <a href="./downloadFiles/实验室使用情况导入模板.xlsx" target="_blank"
            style="text-decoration: #3378fa; color:#3378fa;font-size: 14px;">
            <i class="el-icon-download"></i> 下载模板</a>
        </el-form-item>

        <el-form-item label="选择学期" class="required_form" style="overflow:hidden">
          <el-select size="small" v-model="importForm.term_id" filterable placeholder="学期" style=""
            @change="$forceUpdate()">
            <el-option v-for="(item, index) in termList" :key="index" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="上传文件" class="required_form" style="overflow:hidden">
          <el-upload action="" :http-request="uploadTemp" :on-success="uploadFiles" :show-file-list="false">
            <el-button size="small" type="primary" plain>选择文件</el-button>
          </el-upload>
          <div style="font-size:14px;color:#606266;line-height: normal;"> {{ importForm.fileName }}
          </div>

          <!-- <input type="file" @change="handleFileData(this)" style=""
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" /> -->
        </el-form-item>
      </el-form>




      <div slot="footer" class="dialog-footer">
        <el-button @click="showImportDialog = false" type="primary" plain>关 闭</el-button>
        <el-button @click="saveFormImport('import')" type="primary" icon="el-icon-upload">确定导入</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import DeptUser from "../../com/deptuser.vue";
import { ExportBriefDataDocx, getBase64Sync } from "@/utils/exportBriefDataDocx";
import moment from 'moment'
import util from "@/utils/util";
export default {
  components: {
    DeptUser,
  },
  data() {
    return {
      userinfo:{},
      dialogVisible: false,
      report_year: "",
      week: "",
      weekList: [],
      year_week: new Date(),
      sysID: 0,
      sysName: "",
      sysList: [],
      courseList: [],
      classList:[],
      activeTabIndex: 0,
      activeIndex: 0, //教室
      queryForm: {},
      termList: [],
      termIdInit: "",
      value: "",
      registerList: [],
      sysInfo: {},
      EditItem: {
        register_date: "",
        room_id: "",
        course_id: "",
        class_id: ""
      },

      page: {
        current_page: 1,
        per_page: 20,
        count: 0,
        pageSizes: [10, 50, 100],
      },
      showImportDialog: false,
      importForm: {},
      termList: [],
      termIdInit: ''
    };
  },
  computed: {
    showWeek() {
      return `${this.report_year} 第 ${this.week} 周`;
    },
  },
  watch: {
    "EditItem.register_date"(value) {
      if (value) {
        this.weekList.map(week => {
          if (value >= week.start && value <= week.end) {
            this.week = week.index - 1
          }
        })
      }
    },
  },
  mounted() {

    this.getTerm().then((_) => {
      this.getSysList();
    });

    // this.changeDate()
    this.$http.post("/api/get_teacher_info", {}).then((res) => {
      this.userinfo = res.data;
    });

    this.getCourse();
    if (this.$route.query.roomId) {
      this.sysID = Number(this.$route.query.roomId);
    }
  },
  methods: {
    addNew() {
      this.termIdInit = this.queryForm.term_id;
      this.EditItem.room_id = this.sysID;
      this.EditItem.register_date = this.$moment().format('YYYY-MM-DD')
      if (this.courseList.length == 1) {
        this.EditItem.course_id = this.courseList[0].course_id
      }
      if (this.classList.length == 1) {
        this.EditItem.class_id = this.classList[0]
      }
      this.dynamicFillVal(this.sysID,'room')
      this.dialogVisible = true
    },
    getRegisterList() {
      this.$http
        .post("/api/sxjd_register_list", {
          pagesize: 90000000,
          term_id: this.queryForm.term_id,
          room_id: this.sysID,
          week: this.queryForm.week
        })
        .then((res) => {
          this.registerList = res.data.data;
          this.page.count = res.data.data.length
          // console.log(840,this.registerList)
        });
    },
    getDefaultWeek() {
      const now = new Date();
      const start = new Date(now.getFullYear(), 0, 1);
      const dayOfWeek = start.getDay();
      const adjustedStart = start.getDate() + dayOfWeek * -1;

      const firstThursday = new Date(start.setDate(adjustedStart + 3));
      const firstWeek = new Date(+firstThursday);

      const time = firstWeek.getTime();
      const day = (now.getTime() - time + now.getTimezoneOffset() * 60000) % (7 * 24 * 60 * 60 * 1000);

      const currentWeekNumber = Math.floor(day / (7 * 24 * 60 * 60 * 1000)) + 1;

      return firstWeek.getFullYear() + "第" + currentWeekNumber + "周";
    },



    getTerm() {
      return new Promise((resolve) => {
        this.$http.post(`/api/t_sch_terms_list`).then((res) => {
          if (res && res.data.data && res.data.data.length > 0) {
            this.termList = res.data.data;
            res.data.data &&
              res.data.data.map((a) => {
                if (this.globalCurrentDate > a.term_start && this.globalCurrentDate < a.term_end) {
                  this.termIdInit = a.id;
                  this.queryForm.term_id = this.termIdInit;
                  this.importForm.term_id = this.termIdInit
                }
              });
            if (!this.termIdInit) {
              this.termIdInit = res.data.data[0].id;
              this.queryForm.term_id = this.termIdInit;
              this.importForm.term_id = this.termIdInit
            }
          }
          this.getWeek()
          this.$forceUpdate();
          resolve();
        });
      });
    },
    getSysList() {
      this.$http
        .post("/api/syskcb_rooms", {
          pagesize: 2000,
          term_id: this.queryForm.term_id,
        })
        .then((res) => {
          this.sysList = res.data;
          if (this.sysID) {
            this.sysName = this.sysList.filter((s) => s.id === this.sysID).map((s) => s.room_name);
            this.sysInfo = this.sysList.filter((s) => s.id === this.sysID)[0];
          } else {
            this.sysID = this.sysList[0].id;
            this.sysName = this.sysList[0].room_name;
            this.sysInfo = this.sysList[0];
          }
          this.getData();
        });
    },
    getCourse() {
      this.$http
        .post("/api/t_sch_syskc_list", {
          pagesize: 200,
          term_id: this.queryForm.term_id,
        })
        .then((res) => {
          let courseList = res.data;

          this.courseList = this._lo.uniqBy(courseList, (item) => item.course_id);
          this.getTeacherClass()
        });
    },
    getData() {
      //获取登记表
      this.registerList = [];
      this.$forceUpdate();
      this.getRegisterList();
    },

    queryCourseTable() {
      this.sysID = ""
      this.getSysList()
    },

    chooseSys() {
      this.sysList.map((a, ia) => {
        if (this.sysID == a.id) {
          this.sysInfo = a;
          this.sysID = a.id;
          this.sysName = a.room_name;
          this.activeIndex = ia;
          this.getData();
          this.jump(ia);
          return;
        }
      });
    },
    getSchoolTimetable(row) {
      this.sysInfo = row;
      this.sysID = row.id;
      this.sysName = row.room_name;
      this.sysList.map((a, ia) => {
        if (a.id == row.id) {
          this.activeIndex = ia;
          return;
        }
      });
      this.getData();
    },

    changeDate(e) {
      let str = this.$moment(this.year_week).utcOffset(480).format("yyyy-WW");
      this.weekStart = this.$moment(this.year_week).utcOffset(480).format("yyyy-MM-DD");
      this.weekEnd = this.$moment(this.year_week).add(6, "day").utcOffset(480).format("yyyy-MM-DD");

      let arr = str.split("-");
      this.report_year = arr[0];
      this.week = arr[1];

      this.getData();
    },



    deleteItem(e) {
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http.post("/api/sxjd_register_delete", { id: e.id }).then((res) => {
          this.$message.success("删除成功");
          this.getData();

        });
      });
    },
    editRow(e){
        this.EditItem = e
        this.dialogVisible = true
    },

    jump(index) {
      let target = document.querySelector(".listBox");

      let scrollItems = document.querySelectorAll(".listItem");

      // 判断滚动条是否滚动到底部

      if (target.scrollHeight <= target.scrollTop + target.clientHeight) {
        this.activeStep = index;
      }

      let total = scrollItems[index].offsetTop - scrollItems[0].offsetTop; // 锚点元素距离其offsetParent(这里是body)顶部的距离(待滚动的距离)

      let distance = document.querySelector(".listBox").scrollTop; // 滚动条距离滚动区域顶部的距离

      // let distance = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset // 滚动条距离滚动区域顶部的距离(滚动区域为窗口)

      // 滚动动画实现, 使用setTimeout的递归实现平滑滚动，将距离细分为50小段，10ms滚动一次

      // 计算每一小段的距离

      let step = total / 50;

      if (total > distance) {
        smoothDown(document.querySelector(".listBox"));
      } else {
        let newTotal = distance - total;

        step = newTotal / 50;

        smoothUp(document.querySelector(".listBox"));
      }

      // 参数element为滚动区域

      function smoothDown(element) {
        if (distance < total) {
          distance += step;

          element.scrollTop = distance;

          setTimeout(smoothDown.bind(this, element), 10);
        } else {
          element.scrollTop = total;
        }
      }

      // 参数element为滚动区域

      function smoothUp(element) {
        if (distance > total) {
          distance -= step;

          element.scrollTop = distance;

          setTimeout(smoothUp.bind(this, element), 10);
        } else {
          element.scrollTop = total;
        }
      }
    },

    toBase64(url) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
          const reader = new FileReader();
          reader.onloadend = function () {
            let dataURL = reader.result
            const base64Regex = /^data:image\/(png|jpg|jpeg|svg|svg\+xml);base64,/;
            if (!base64Regex.test(dataURL)) {
              return false;
            }
            const stringBase64 = dataURL.replace(base64Regex, "");
            let binaryString;
            if (typeof window !== "undefined") {
              binaryString = window.atob(stringBase64);
            } else {
              binaryString = Buffer.from(stringBase64, "base64").toString("binary");
            }
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              const ascii = binaryString.charCodeAt(i);
              bytes[i] = ascii;
            }
            resolve(bytes.buffer);
          };
          reader.onerror = reject;
          reader.readAsDataURL(xhr.response);
        };
        xhr.onerror = reject;
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
      });
    },

    convertImageToBase64(url, callback) {

      fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok " + response.statusText);
          }
          console.log(1936, response);
          response.body();
        })
        .then((blob) => {
          console.log(":::::::::::::::");
          const reader = new FileReader();
          reader.onload = (e) => callback(e.target.result);
          reader.readAsDataURL(blob);
        })
        .catch((error) => console.error("Error converting image to base64:", error));
    },



    async exportWord_register() {
      let index = 0;
      let list = [];
      let term_name = "";

      let registerList = JSON.parse(JSON.stringify(this.registerList))
      for (let item of registerList) {
        index += 1;
        item.index = index;
        item.class_name = item.class_name || item.class_id;
        //item.handsign = item.handsign ? await this.getBase64Image(item.handsign, 200, 200) : ''
        //item.handsign = ''
        if (item.handsign) {
          item.handsign = await util.getBase64Sync(item.handsign)
        }
        item.test_num = item.test_num || ""
        item.group_num = item.group_num || ""
        item.class_hour = item.class_hour || ""

        list.push(item);
        // 使用示例
        // console.log(1951,item.handsign)
        // if (item.handsign) {
        //   item.handsign = await this.toBase64("//ngzzc-api.aiwx.org.cn/api/image?src=" + item.handsign)
        // }
      }
      this.termList.map((a, ia) => {
        // console.log(2222, a, this.queryForm.term_id);
        if (a.id == this.queryForm.term_id) {
          term_name = a.title;
          return;
        }
      });
      let info = this.sysInfo;
      info.list = list;
      info.term_name = term_name;
      info.dept_name = info.dept_name || "电气工程系";
      info.gly = info.gly || "";
      setTimeout(() => {
        // console.log(2071, info);
        let imgSize = {
          //控制导出的word图片大小，可自定义
          //imgurl: [200, 200],
          //imgurl2: [200, 200],
          handsign: [75, 40],
        };
        // exportWord('./static/ImportTemps/实训基地使用情况登记表[导出数据模板].doc', info, `${this.sysName}使用情况登记表.doc`)
        console.log(imgSize)
        ExportBriefDataDocx("/static/ImportTemps/实训基地使用情况登记表[导出数据模板].docx", info, `${this.sysName}使用情况登记表.docx`, imgSize); // text.docx放在了根目录下的public文件夹下
      }, 200);
    },

    getWeek(term_id) {
      let ckdTerm = null
      for (let term of this.termList) {
        if (term.id == this.termIdInit) {
          ckdTerm = term;
          break
        }
      }
      let s = ckdTerm.term_start
      let e = ckdTerm.term_end
      let weeks = this.getAllWeeksBetween(s, e);
      this.weekList = weeks
      // console.log(108, weeks)
      weeks.map(week => {
        // console.log(`Week starts on: ${week.start}, ends on: ${week.end}`);
        let globalCurrentDate = moment().format('YYYY-MM-DD')
        if (globalCurrentDate >= week.start && globalCurrentDate <= week.end) {
          console.log(week);
          this.week = week.index - 1
        }
      })
    },

    getAllWeeksBetween(startDate, endDate) {

      let current = new Date(startDate);
      let end = new Date(endDate);
      let weeks = [];

      // 确保开始日期是周一
      current.setDate(current.getDate() - current.getDay() + 1);
      let index = 0//从开始日期计算为第一周
      while (current <= end) {
        index += 1
        // 周的开始是周一（索引为1），周结束是周日（索引为0）
        let weekStart = new Date(current.getTime());
        let weekEnd = new Date(current.getTime());
        weekEnd.setDate(weekEnd.getDate() + 6);

        let start = moment(new Date(weekStart)).format("YYYY-MM-DD")
        let end = moment(new Date(weekEnd)).format("YYYY-MM-DD")
        weeks.push({
          index: index,
          start: start,
          end: end,
          title: `第${index}周 （${start} ~ ${end}）`
        });

        // 下一周
        current.setDate(current.getDate() + 7);
      }

      return weeks;
    },
    getTeacherClass(){
      this.$http.post("/api/teacher_course_classes", { term_id: this.termIdInit }).then((res) => {
          this.classList = res.data
        this.getTeacherSign()
        });
      
    },
    getTeacherSign(){
      this.$http.post("/api/get_sign_pic").then((res) => {
          this.userinfo.handsign = res.data.handsign

        });
      
    },
    saveSysCourse(){
      this.EditItem.term_id = this.termIdInit
      this.EditItem.weekly = this.week
      console.log(this.EditItem);
      if(!this.EditItem.register_date){
        this.$message.error("请选择日期")
        return
      }
      if(!this.EditItem.class_id){
        this.$message.error("请选择班级")
        return
      }
      if(!this.EditItem.course_id){
        this.$message.error("请选择课程")
        return
      }
      if(!this.EditItem.room_id){
        this.$message.error("请选择实验室!")
        return
      }
      this.$http.post("/api/sxjd_register_edit",this.EditItem).then((res) => {
          this.getData()
          this.$message.success("提交成功")
          this.EditItem={}
          this.dialogVisible = false

        });
    },
    onClose() {
            this.EditItem = {
              register_date: "",
              room_id: "",
              course_id: "",
              class_id: ""
            };
        },
    dynamicFillVal(val,type) {
      if (this.courseList.length === 2) {
        if (type === 'class') {
          for (let c of this.courseList) {
            if (c.class_name == val) {
              this.EditItem.course_id = c.course_id
              this.EditItem.room_id = c.roomid
              break
            }
          }
        } else if (type === 'course') {
          for (let c of this.courseList) {
            if (c.course_id == val) {
              this.EditItem.class_id = c.class_name
              this.EditItem.room_id = c.roomid
              break
            }
          }
        } else if (type === 'room') {
          for (let c of this.courseList) {
            if (c.roomid == val) {
              this.EditItem.class_id = c.class_name
              this.EditItem.course_id = c.course_id
              break
            }
          }
        }
      }
    },

    uploadFiles(e) {
      console.log(e)
      this.importForm.fileName = e.fileName
      this.importForm.url = e.src
      this.$forceUpdate()
    },

    saveFormImport(action) {
      let _this = this
      console.log(476, this.importForm.term_id)
      let flag = 1
      let arr = []
      if (!this.importForm.term_id) {
        flag = 0; this.$message.error(`请选择关联学期~`); return;
      }


      let termObj = this._lo.find(_this.termList, function (o) { return o.id == _this.importForm.term_id; })


      if (!this.importForm.url || this.importForm.url == '') {
        flag = 0; this.$message.error(`请选择excel文件并上传~`); return;

      }


      if (flag) {
        console.log(333, arr)
        this.$confirm(`您已选择【${termObj.title}】，是否确定导入该学期的实验室使用情况？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {


          let loadingInstance = this.$loading({
            lock: true,
            text: "正在导入，请稍等....",
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.3)',
          })
          this.$http
            .post('/api/sys_register_import_xls', {
              term_id: this.importForm.term_id,
              url: this.importForm.url,
            })
            .then((res) => {
              if (res.data.errors && res.data.errors.length > 0) {
                let html = "";
                for (let msg of res.data.errors) {
                  html +=
                    "<div style='font-size:12px'>" + msg + "</div>";
                }
                this.$alert(html, "未导入数据", {
                  dangerouslyUseHTMLString: true,
                });
              } else {
                this.$message.success("导入成功");
                this.getData();
                this.showImportDialog = false;
              }
            });
          loadingInstance.close()

        })
      }

    },




  },
};
</script>
<style lang="less" type="text/less">
.syssy {
  .el-input.is-disabled .el-input__inner {
    color: #606266;
  }
}

.cepContact .el-col {
  display: flex;
  padding: 0;
  margin: 0;
}

.cepContact .el-row {
  width: 100%;
  padding: 0;
  margin: 0;
}
</style>
<style scoped="scoped" lang="less" type="text/less">
.bgLineHeight {
  background-color: rgba(24, 144, 255, 0.08);
}

.cepContact {
  margin-top: 25px;
  width: 100%;
  height: calc(100% - 25px);
  overflow-y: auto;

  .title {
    font-size: 20px;
    line-height: 50px;
  }

  .form {
    width: 100% - 0px;
    border: 1px solid #c0d2ff;
    border-top: 0;

    div {
      font-size: 14px;
      color: #323232;
      box-sizing: border-box;
    }

    .content {
      width: 100%;
      height: 100%;
      padding: 10px;
      white-space: break-spaces;
      word-break: break-all;
      overflow: hidden;
    }
  }

  .col-row {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .table {
    width: 100%;
  }

  .borderR {
    border-right: 1px solid #c0d2ff;
  }

  .borderB {
    border-bottom: 1px solid #c0d2ff;
  }

  .cell {
    border-left: 1px solid #c0d2ff;
    border-top: 1px solid #c0d2ff;

    min-height: 30px;
    //line-height: 30px;
    //text-align: center;
    white-space: nowrap;
    font-size: 14px;
    word-break: break-all;
    white-space: normal;
  }

  .cell50 {
    min-height: 50px;
    line-height: 50px;
    font-size: 14px;
  }

  .cellMore {
    border-left: 1px solid #c0d2ff;
    border-top: 1px solid #c0d2ff;
    min-height: 50px;
    text-align: left;
    font-size: 14px;
  }
}

.dttable {
  /*table-layout: fixed;*/

  td {
    text-align: center;
    word-wrap: break-word;
    width: auto !important;
  }

  tbody td {
    /*cursor: pointer;*/
  }
}

.cbox {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0;

  div {
    box-sizing: border-box;
  }

  .el-table__empty-block {
    width: 100% !important;
  }

  .tabBox {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #98b9fc;

    .tabBar {
      height: 100%;
      align-items: end;

      .tabItem {
        height: 30px;
        border: 1px solid #3f4bda;
        margin-left: 15px;
        padding: 0 5px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        margin-bottom: -1px;
        cursor: pointer;
      }

      .activeTab {
        background-color: #2469f3;
        border: 0;
        color: #fff;
      }

      .activeTabTgt {
        background-color: #3e4ddb;
        border: 0;
        color: #fff;
      }
    }
  }

  .tabContent {
    padding: 20px 15px;
    height: calc(100% - 40px);

    .dialog_left {
      width: 300px;
      height: 100%;
      overflow: hidden;

      .bgGrey {
        margin-top: 20px;
        padding: 20px 0;
        height: calc(100% - 20px);
        border-radius: 15px;
        background-color: rgba(24, 144, 255, 0.08);

        .listBox {
          height: 100%;
          padding: 0 15px;
          overflow-y: auto;
          height: calc(100% - 50px);

          .listItem {
            margin-bottom: 20px;
            cursor: pointer;

            .dotted {
              margin-right: 10px;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background-color: #7a7a7a;
            }

            .title {
              color: #7a7a7a;
              font-weight: 700;
            }

            .icon {
              margin-left: 10px;
              color: #3073f7;
              font-weight: 700;
            }
          }

          .activeItem {
            .dotted {
              background-color: #3073f7;
            }

            .title {
              color: #3073f7;
            }
          }
        }
      }
    }

    .dialog_right {
      margin-left: 30px;
      width: calc(100% - 330px);
      height: 100%;
      overflow: hidden;

      .bcontent {
        width: 100%;
        height: 100%;
        overflow: hidden;
      }

      .formBox {
        width: 100%;
        height: calc(100% - 50px);
        margin-top: 30px;

        .tabBox {
          width: 100%;
          height: 40px;
          border-bottom: 1px solid #98b9fc;
          align-items: end;

          .tabBar {
            height: 100%;
            align-items: end;
            padding-left: 40px;

            .tabItem {
              height: 30px;
              border: 1px solid #3f4bda;
              margin-right: 15px;
              padding: 0 5px;
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
              margin-bottom: -1px;
              cursor: pointer;
              font-size: 14px;
              white-space: nowrap;
            }

            .activeTab {
              background-color: #2469f3;
              border: 0;
              color: #fff;
            }

            .activeTabTgt {
              background-color: #3e4ddb;
              border: 0;
              color: #fff;
            }
          }
        }

        .tabContent {
          width: 100%;
          height: calc(100% - 100px);
          padding-left: 40px;
        }
      }

      .targetItem {
        margin-bottom: 10px;
        width: 100%;
      }
    }
  }
}
</style>
