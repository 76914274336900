import { render, staticRenderFns } from "./com_myLY.vue?vue&type=template&id=72059734"
import script from "./com_myLY.vue?vue&type=script&lang=js"
export * from "./com_myLY.vue?vue&type=script&lang=js"
import style0 from "./com_myLY.vue?vue&type=style&index=0&id=72059734&prod&lang=less"
import style1 from "./com_myLY.vue?vue&type=style&index=1&id=72059734&prod&scope=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports