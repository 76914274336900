<template>
    <div class="pageContainer flexCloumn cbox">
        <el-row class="flexBetween searchBar" style="flex-wrap: wrap;width:100%">
            <div class="flexStart flex_1">
                <el-select size="small" v-model="queryForm.term_id" filterable placeholder="学期" style=""
                    @change="$forceUpdate()">
                    <el-option v-for="(item, index) in termList" :key="index" :label="item.title"
                        :value="item.id"></el-option>
                </el-select>

                <el-input class="ml10" v-model="queryForm.course_name" size="small" clearable placeholder="请输入课程名称"
                    style="width:150px;"></el-input>

                <el-input class="ml10" v-model="queryForm.room_name" size="small" clearable placeholder="请输入教室名称"
                    style="width:150px;"></el-input>

                <el-input class="ml10" v-model="queryForm.teacher_name" size="small" clearable placeholder="请输入教师名称"
                    style="width:150px;"></el-input>

                <el-button class="ml10" size="small" type="primary" icon="el-icon-search" style="display:inline-block"
                    @click="getList">查询</el-button>
                <!-- <el-button size="small" type="primary" icon="el-icon-plus" style="display:inline-block"
                    @click="addForm">添加课程</el-button> -->

            </div>
            <div class="flexStart">
                <el-button size="small" type="primary" plain style="display:inline-block" icon="el-icon-upload"
                    @click="showImportDialog = true">导入
                </el-button>
              <el-button size="small" type="primary" plain style="display:inline-block" icon="el-icon-download"
                         @click="exportKcb">导出
              </el-button>
            </div>
        </el-row>
        <div class="dividerBar"></div>



        <el-table :data="DataList" height="100%" stripe border size="small" style="width:fit-content;width:100%;">
            <el-table-column type="index" label="编号" width="55" align="center"></el-table-column>


            <el-table-column prop="course_name" label="课程名称" min-width="185" align="center"></el-table-column>
            <!-- <el-table-column prop="course_desc" label="课程描述" min-width="300" align="center"></el-table-column> -->
            <el-table-column prop="class_name" label="授课班级" width="120" align="center"></el-table-column>
            <el-table-column prop="teacher_name" label="授课老师" width="120" align="center"></el-table-column>
            <el-table-column prop="weekday" label="授课周" width="100" align="center">
                <template slot-scope="scope">
                    <span style="" @click="view(scope.row)">周{{ scope.row.weekday
                        }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="jie" label="授课课时" width="100" align="center"></el-table-column>
            <el-table-column prop="room_name" label="授课教室" min-width="200" align="center">
                <template slot-scope="scope">
                    <span style="" @click="view(scope.row)">{{ scope.row.room_name
                        }}({{ scope.row.address }})</span>
                </template>
            </el-table-column>

            <el-table-column v-if="false" label="操作" width="150" fixed="right" align="center">
                <template slot-scope="scope">
                    <!-- <el-tag size="mini" style="cursor: pointer" @click="editItem(scope.row)"><i
                            class="el-icon-edit"></i>编辑</el-tag> -->

                    <el-tag size="mini" type="danger" style="margin-left: 10px;cursor: pointer"
                        @click="deleteItem(scope.row)"><i class="el-icon-delete"></i>删除</el-tag>

                </template>
            </el-table-column>

        </el-table>

        <el-pagination class="cus_page" @size-change="handleSizeChange" @current-change="paginate"
            :current-page.sync="page.current_page" :page-sizes="[10, 20, 50, 100]" :page-size="page.per_page"
            layout="total" :total="page.count" style="margin-top: 10px;">
        </el-pagination>

        <!-- <el-pagination class="cus_page" @size-change="handleSizeChange" @current-change="paginate"
            :current-page.sync="page.current_page" :page-sizes="[10, 20, 50, 100]" :page-size="page.per_page"
            layout="total,sizes, prev, pager, next" :total="page.count" style="margin-top: 10px;">
        </el-pagination> -->




        <el-dialog title="编辑课程" :visible.sync="dialogVisible" custom-class="width_800 cus_dialog"
            :close-on-click-modal="false">
            <el-form :model="EditItem" label-width="120px">
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="课程名称" prop="title">
                            <el-input v-model="EditItem.course_name" placeholder="请输入课程名称"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="20">
                        <el-form-item label="课程描述" prop="title">
                            <el-input type="textarea" v-model="EditItem.course_desc" placeholder="请输入课程描述"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="20">
                        <el-form-item label="代课老师" prop="title">

                            <el-input v-model="EditItem.username" placeholder="请输入代课老师"
                                @focus="showDeptUserDialog"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>


                <el-row style="text-align:right">
                    <el-col :span="20">
                        <el-button type="primary" icon="el-icon-check" style="display:inline-block"
                            @click="saveForm">保存</el-button>
                    </el-col>

                </el-row>

            </el-form>

        </el-dialog>


        <DeptUser :odata="[EditItem]" v-on:getUser="getUser" v-if="showUserDialog"></DeptUser>


        <!-- <el-dialog title="导入" :visible.sync="showImportDialog1" custom-class="cus_dialog" width="20%"
            style="height: 300px;" :close-on-click-modal="false">
            <el-button icon="el-icon-download" type="text">
                <a href="./downloadFiles/实验室导入模板.xlsx" target="_blank"
                    style="text-decoration: none; color: inherit;font-size: 14px;">下载模板</a>
            </el-button>
            <el-upload style="text-align: center;margin-top: 10px;" action="" :http-request="uploadTemp"
                :on-success="uploadFiles" :show-file-list="false">
                <el-button size="small" icon="el-icon-upload2" type="primary">选择文件</el-button>
            </el-upload>
        </el-dialog> -->
        <!-- 导入 -->
        <el-dialog :visible.sync="showImportDialog" :close-on-click-modal="false" :destroy-on-close="true"
            custom-class="cus_dialog cus_dialogMin" width="600px">
            <div slot="title" class="flexBetween">
                <span style="color: #ffffff;">导入课程表</span>
            </div>

            <el-form :model="importForm" size="small" label-width="110px" style="overflow-y: auto;"
                label-position="left">

                <el-form-item label="下载模板" class="">
                    <a href="./downloadFiles/实验室课程表导入模板.xlsx" target="_blank"
                        style="text-decoration: #3378fa; color:#3378fa;font-size: 14px;">
                        <i class="el-icon-download"></i> 下载模板</a>
                </el-form-item>

                <el-form-item label="选择学期" class="required_form" style="overflow:hidden">
                    <el-select size="small" v-model="importForm.term_id" filterable placeholder="学期" style=""
                        @change="$forceUpdate()">
                        <el-option v-for="(item, index) in termList" :key="index" :label="item.title"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="上传文件" class="required_form" style="overflow:hidden">
                    <el-upload action="" :http-request="uploadTemp" :on-success="uploadFiles" :show-file-list="false">
                        <el-button size="small" type="primary" plain>选择文件</el-button>
                    </el-upload>
                    <div style="font-size:14px;color:#606266;line-height: normal;"> {{ importForm.fileName }}
                    </div>

                    <!-- <input type="file" @change="handleFileData(this)" style=""
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" /> -->
                </el-form-item>
            </el-form>




            <div slot="footer" class="dialog-footer">
                <el-button @click="showImportDialog = false" type="primary" plain>关 闭</el-button>
                <el-button @click="saveFormImport('import')" type="primary" icon="el-icon-upload">确定导入</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

import DeptUser from '../../com/deptuser.vue'

export default {
    components: {
        DeptUser
    },
    data() {
        return {
            queryForm: {
                keyword: "",
                ktype: "course_name"
            },
            dialogVisible: false,
            showUserDialog: false,
            DataList: [],
            page: {
                current_page: 1,
                per_page: 20,
                count: 0,
                pageSizes: [10, 50, 100]
            },

            EditItem: {
                course_name: "",
                course_desc: "",
                teacher_id: "",
                username: ""
            },

            showImportDialog: false,
            importForm: {},
            termList: [],
            termIdInit: ''
        };
    },

    mounted() {
        this.getTerm()
        this.$http.post("/api/get_teacher_info", {

        }).then(res => {
            this.userinfo = res.data
            this.getList()
        })

    },
    methods: {

        getTerm() {
            return new Promise(resolve => {
                this.$http.post(`/api/t_sch_terms_list`).then(res => {
                    if (res && res.data.data && res.data.data.length > 0) {
                        this.termList = res.data.data
                        res.data.data && res.data.data.map(a => {
                            console.log(a)
                            if (this.globalCurrentDate > a.term_start && this.globalCurrentDate < a.term_end) {
                                this.termIdInit = a.id
                                this.queryForm.term_id = this.termIdInit
                                this.importForm.term_id = this.termIdInit
                                console.log(322, this.importForm.term_id)
                            }
                        })
                        if (!this.termIdInit) {
                            this.termIdInit = res.data.data[0].id
                            this.queryForm.term_id = this.termIdInit
                            this.importForm.term_id = this.termIdInit
                        }
                        console.log(322, this.importForm.term_id)
                    }
                    this.$forceUpdate()
                    resolve()
                })
            })
        },
        getList() {
            let params = Object.assign({
                page: this.page.current_page,
                pagesize: this.page.per_page,
            }, this.queryForm)
            this.$http.post("/api/t_sch_syskc_list", params).then(res => {
                // this.DataList = res.data.data
                // this.page = res.data.page
                this.DataList = res.data
                this.page.count = res.data.length

            })
        },
        handleSizeChange(val) {
            this.page.current_page = 1
            this.page.per_page = val
            this.getList();
        },
        paginate(val) {
            this.page.current_page = val
            this.getList();
        },
        addForm() {
            this.EditItem = {
                course_name: "",
                course_desc: "",
                teacher_id: "",
                username: ""
            }
            this.dialogVisible = true
        },
        saveForm() {
            this.$http.post("/api/t_sch_syskc_edit", this.EditItem).then(res => {
                this.$message.success("保存成功")
                this.getList()
                this.dialogVisible = false
            })
        },
        editItem(e) {
            this.EditItem = e;
            this.dialogVisible = true
        },
        deleteItem(e) {
            this.$confirm('是否确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post('/api/t_sch_syskc_delete', { id: e.id }).then(res => {
                    this.$message.success('删除成功');
                    this.getList()
                })
            })
        },
        getUser(e) {
            console.log('----------------')
            console.log(e)
            if (e && e.length > 0) {
                this.EditItem.teacher_id = e[0].id
                this.EditItem.username = e[0].username
            }
            this.showUserDialog = false
        },
        showDeptUserDialog() {
            //alert(111)
            this.showUserDialog = true
        },
        uploadFiles(e) {
            console.log(e)
            this.importForm.fileName = e.fileName
            this.importForm.url = e.src
            this.$forceUpdate()
            return
            let loadingInstance = this.$loading({
                lock: true,
                text: "正在导入，请稍等....",
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.3)',
            })
            this.$http
                .post('/api/sys_rooms_import', {
                    url: e.src,
                })
                .then((res) => {
                    this.$message.success("导入成功");
                    if (res.data.errors && res.data.errors.length > 0) {
                        let html = "";
                        for (let msg of res.data.errors) {
                            html +=
                                "<div style='font-size:12px'>" + msg + "</div>";
                        }
                        this.$alert(html, "未导入数据", {
                            dangerouslyUseHTMLString: true,
                        });
                    }
                    this.getList();
                    this.showImportDialog = false;
                    loadingInstance.close()
                });
        },

        saveFormImport(action) {
            let _this = this
            console.log(476, this.importForm.term_id)
            let flag = 1
            let arr = []
            if (!this.importForm.term_id) {
                flag = 0; this.$message.error(`请选择关联学期~`); return;
            }


            let termObj = this._lo.find(_this.termList, function (o) { return o.id == _this.importForm.term_id; })


            if (!this.importForm.url || this.importForm.url == '') {
                flag = 0; this.$message.error(`请选择excel文件并上传~`); return;

            }


            if (flag) {
                console.log(333, arr)
                this.$confirm(`您已选择【${termObj.title}】，是否确定导入该学期的课程安排表？`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {


                    let loadingInstance = this.$loading({
                        lock: true,
                        text: "正在导入，请稍等....",
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.3)',
                    })
                    this.$http
                        .post('/api/syskcb_import_xls', {
                            term_id: this.importForm.term_id,
                            url: this.importForm.url,
                        })
                        .then((res) => {
                            if (res.data.errors && res.data.errors.length > 0) {
                                let html = "";
                                for (let msg of res.data.errors) {
                                    html +=
                                        "<div style='font-size:12px'>" + msg + "</div>";
                                }
                                this.$alert(html, "未导入数据", {
                                    dangerouslyUseHTMLString: true,
                                });
                            } else {
                                this.$message.success("导入成功");
                                this.getList();
                                this.showImportDialog = false;
                            }
                        });
                    loadingInstance.close()

                })
            }

        },
      exportKcb() {
        this.$http.post('/api/syskcb_export',{term_id:this.queryForm.term_id}).then(res => {
          window.open(res.data.url,'_blank')
        })
      },
    },
}
</script>

<style scoped="scoped">
.dttable td {
    text-align: center;
}
</style>
