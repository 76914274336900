import { render, staticRenderFns } from "./sysjcjh.vue?vue&type=template&id=0a15e525&scoped=true"
import script from "./sysjcjh.vue?vue&type=script&lang=js"
export * from "./sysjcjh.vue?vue&type=script&lang=js"
import style0 from "./sysjcjh.vue?vue&type=style&index=0&id=0a15e525&prod&scoped=scoped&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a15e525",
  null
  
)

export default component.exports