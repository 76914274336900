<template>
    <div class="pageContainer flexCloumn cbox">
        <el-row class="flexBetween searchBar" style="flex-wrap: wrap;width:100%">
            <div class="flexStart flex_1">
                <el-select size="small" v-model="queryForm.term_id" filterable placeholder="学期" style=""
                    @change="onTermChange">
                    <el-option v-for="(item, index) in termList" :key="index" :label="item.title"
                        :value="item.id"></el-option>
                </el-select>


                <el-button class="ml10" size="small" type="primary" icon="el-icon-search" style="display:inline-block"
                    @click="getList">查询</el-button>
                <span style="color: #e6a23c;font-size: 14px;font-weight: bold;"><i class="el-icon-warning"></i>
                    双击单元格进行编辑，编辑完成后点击保存</span>
            </div>
            <div class="flexStart">
                <el-button size="small" type="primary" plain style="display:inline-block;margin-right:20px;"
                    icon="el-icon-upload" @click="showImportDialog = true">导入
                </el-button>

                <el-button size="small" type="primary" plain style="display:inline-block" icon="el-icon-down"
                    @click="exportExcel">导出
                </el-button>
            </div>
        </el-row>
        <div class="dividerBar"></div>


        <el-table :data="DataList" height="100%" :key="key" stripe border size="small" ref="elTable"
            style="width:fit-content;width:100%;" @cell-dblclick="editItem">
            <el-table-column type="index" label="序号" width="55" align="center"></el-table-column>
            <el-table-column prop="address" label="基地房间号" width="120" align="center"></el-table-column>
            <!-- <el-table-column prop="course_desc" label="课程描述" min-width="300" align="center"></el-table-column> -->
            <el-table-column prop="room_name" label="基地名称" width="260" show-overflow-tooltip
                align="center"></el-table-column>
            <el-table-column prop="gly" label="管理员" width="100" align="center"></el-table-column>
            <el-table-column label="实际使用课时" align="center">
                <el-table-column v-for="item, index in tableMonths" :key="item.prop" :prop="item.prop"
                    :label="item.label" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.sjks[index] ? scope.row.sjks[index].ks : 0 }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="合计" align="center">
                    <template slot-scope="scope">{{ sjksSum(scope.row.sjks) }}</template>
                </el-table-column>
            </el-table-column>
            <el-table-column prop="jhks" label="计划课时" align="center"></el-table-column>
            <el-table-column prop="syl" label="使用率" align="center">

            </el-table-column>
            <el-table-column prop="kcl" label="开出率" align="center">

            </el-table-column>

        </el-table>

        <el-pagination class="cus_page" @size-change="handleSizeChange" @current-change="paginate"
            :current-page.sync="page.current_page" :page-sizes="[10, 20, 50, 100]" :page-size="page.per_page"
            layout="total" :total="page.count" style="margin-top: 10px;">
        </el-pagination>

        <!-- <el-pagination class="cus_page" @size-change="handleSizeChange" @current-change="paginate"
            :current-page.sync="page.current_page" :page-sizes="[10, 20, 50, 100]" :page-size="page.per_page"
            layout="total,sizes, prev, pager, next" :total="page.count" style="margin-top: 10px;">
        </el-pagination> -->




        <el-dialog title="编辑" :visible.sync="dialogVisible" custom-class="width_800 cus_dialog" @close="getList"
            :close-on-click-modal="false">
            <el-form :model="EditItem" label-width="120px" :inline="true" style="text-align:left">
                <!-- <el-row> -->
                <!-- <el-col v-for="item, index in tableMonths" :key="item.prop"> -->
                <el-form-item v-for="item, index in tableMonths" :key="item.prop" :label="item.label" :prop="item.prop">
                    <el-input-number v-if="EditItem.sjks" v-model="EditItem.sjks[index].ks" controls-position="right"
                        placeholder="请输入实际使用课时"></el-input-number>
                </el-form-item>
                <!-- </el-col> -->

                <el-row>
                    <el-form-item label="使用率" prop="syl">
                        <el-input-number v-model="EditItem.syl" placeholder="请输入使用率"
                            controls-position="right"></el-input-number>
                    </el-form-item>
                    <!-- </el-col> -->

                    <!-- <el-col> -->
                    <el-form-item label="开出率" prop="kcl">
                        <el-input-number v-model="EditItem.kcl" placeholder="请输入开出率"
                            controls-position="right"></el-input-number>
                    </el-form-item>
                    <!-- </el-col> -->
                </el-row>


                <el-row style="text-align:right">
                    <el-col :span="20">
                        <el-button type="primary" icon="el-icon-check" style="display:inline-block"
                            @click="saveForm">保存</el-button>
                    </el-col>

                </el-row>

            </el-form>

        </el-dialog>

        <el-dialog :visible.sync="showImportDialog" :close-on-click-modal="false" :destroy-on-close="true"
            custom-class="cus_dialog cus_dialogMin" width="600px">
            <div slot="title" class="flexBetween">
                <span style="color: #ffffff;">导入实验室使用情况登记表</span>
            </div>

            <el-form :model="importForm" size="small" label-width="110px" style="overflow-y: auto;"
                label-position="left">
                <el-form-item label="选择学期" class="required_form" style="overflow:hidden">
                    <el-select size="small" v-model="importForm.term_id" filterable placeholder="学期" style=""
                        @change="$forceUpdate()">
                        <el-option v-for="(item, index) in termList" :key="index" :label="item.title"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="下载模板" class="">
                    <a href="javascript:;"
                        style="text-decoration: #3378fa; color:#3378fa;font-size: 14px;" @click="downloadTemp">
                        <i class="el-icon-download"></i> 下载模板</a>

                   
                </el-form-item>



                <el-form-item label="上传文件" class="required_form" style="overflow:hidden">
                    <el-upload action="" :http-request="uploadTemp" :on-success="uploadFiles" :show-file-list="false">
                        <el-button size="small" type="primary" plain>选择文件</el-button>
                    </el-upload>
                    <div style="font-size:14px;color:#606266;line-height: normal;"> {{ importForm.fileName }}
                    </div>

                    <!-- <input type="file" @change="handleFileData(this)" style=""
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" /> -->
                </el-form-item>
            </el-form>




            <div slot="footer" class="dialog-footer">
                <el-button @click="showImportDialog = false" type="primary" plain>关 闭</el-button>
                <el-button @click="saveFormImport('import')" type="primary" icon="el-icon-upload">确定导入</el-button>
            </div>
        </el-dialog>


        <!-- <DeptUser :odata="[EditItem]" v-on:getUser="getUser" v-if="showUserDialog"></DeptUser> -->


        <!-- <el-dialog title="导入" :visible.sync="showImportDialog1" custom-class="cus_dialog" width="20%"
            style="height: 300px;" :close-on-click-modal="false">
            <el-button icon="el-icon-download" type="text">
                <a href="./downloadFiles/实验室导入模板.xlsx" target="_blank"
                    style="text-decoration: none; color: inherit;font-size: 14px;">下载模板</a>
            </el-button>
            <el-upload style="text-align: center;margin-top: 10px;" action="" :http-request="uploadTemp"
                :on-success="uploadFiles" :show-file-list="false">
                <el-button size="small" icon="el-icon-upload2" type="primary">选择文件</el-button>
            </el-upload>
        </el-dialog> -->
    </div>
</template>

<script>

// import DeptUser from '../../../com/deptuser.vue'
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export default {
    // components: {
    //     DeptUser
    // },
    data() {
        return {
            key: Math.random(),
            queryForm: {
                keyword: "",
                ktype: "course_name"
            },
            dialogVisible: false,
            showUserDialog: false,
            DataList: [],
            page: {
                current_page: 1,
                per_page: 20,
                count: 0,
                pageSizes: [10, 50, 100]
            },
            tableMonths: [],
            EditItem: {

            },

            showImportDialog: false,
            importForm: {},
            termList: [],
            termIdInit: ''
        };
    },

    async mounted() {
        await this.getTerm()
        this.$http.post("/api/get_teacher_info", {

        }).then(res => {
            this.userinfo = res.data;
            this.getList()
        })

    },
    methods: {

        getTerm() {
            return new Promise(resolve => {
                this.$http.post(`/api/t_sch_terms_list`).then(res => {
                    if (res && res.data.data && res.data.data.length > 0) {
                        this.termList = res.data.data

                        res.data.data && res.data.data.map(a => {
                            // console.log(a)
                            if (this.globalCurrentDate > a.term_start && this.globalCurrentDate < a.term_end) {
                                this.termIdInit = a.id
                                // this.tableMonths = a.months.map(month => parseInt(month.split('-')[1]) + '月');
                                this.tableMonths = this.getEditItemMonths(a.months)
                                console.log(this.tableMonths)
                                this.queryForm.term_id = this.termIdInit
                                this.importForm.term_id = this.termIdInit
                            }
                        })
                        if (!this.termIdInit) {
                            this.termIdInit = res.data.data[0].id
                            this.tableMonths = this.getEditItemMonths(res.data.data[0].months)
                            console.log(this.tableMonths)
                            this.queryForm.term_id = this.termIdInit
                            this.importForm.term_id = this.termIdInit
                        }
                    }
                    // this.$forceUpdate()
                    resolve()
                })
            })
        },
        getList() {
            let params = Object.assign({
                page: this.page.current_page,
                pagesize: this.page.per_page,
            }, this.queryForm)
            this.$http.post("/api/sys_room_usage", params).then(res => {
                //console.log(res)
                //this.DataList = res.data.data
                // this.page = res.data.page
                this.DataList = res.data
                this.page.count = res.data.length

            })
        },
        handleSizeChange(val) {
            this.page.current_page = 1
            this.page.per_page = val
            this.getList();
        },
        onTermChange(val) {
            this.$forceUpdate();
            for (let i = 0; i < this.termList.length; i++) {
                const item = this.termList[i];
                if (item.id == val) {
                    this.tableMonths = this.getEditItemMonths(item.months)
                    return;
                }
            }
        },

        getEditItemMonths(arr) {
            return arr.map(item => {
                const month = parseInt(item.split('-')[1]);
                return { prop: 'month_' + month, label: month + '月' }
            });
        },

        sjksSum(arr) {
            let sum = 0;
            arr.forEach((item) => {
                sum += item.ks;
            })
            return sum;
        },

        paginate(val) {
            this.page.current_page = val
            this.getList();
        },
        // addForm() {
        //     this.EditItem = {
        //         course_name: "",
        //         course_desc: "",
        //         teacher_id: "",
        //         username: ""
        //     }
        //     this.dialogVisible = true
        // },
        saveForm() {
            console.log(this.EditItem)
            this.$http.post("/api/sys_room_usage_save", this.EditItem).then(res => {
                this.$message.success("保存成功")
                this.getList()
                this.dialogVisible = false
            })
        },
        editItem(e) {
            this.EditItem = e;

            this.dialogVisible = true
        },
        // deleteItem(e) {
        //     this.$confirm('是否确认删除?', '提示', {
        //         confirmButtonText: '确定',
        //         cancelButtonText: '取消',
        //         type: 'warning'
        //     }).then(() => {
        //         this.$http.post('/api/t_sch_syskc_delete', { id: e.id }).then(res => {
        //             this.$message.success('删除成功');
        //             this.getList()
        //         })
        //     })
        // },
        getUser(e) {
            console.log('----------------')
            console.log(e)
            if (e && e.length > 0) {
                this.EditItem.teacher_id = e[0].id
                this.EditItem.username = e[0].username
            }
            this.showUserDialog = false
        },
        showDeptUserDialog() {
            //alert(111)
            this.showUserDialog = true
        },

        exportExcel() {
            // const elTable = this.$refs.elTable;
            // const wb = XLSX.utils.table_to_book(elTable.$el);
            // console.log(wb)
            // const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' });
            // try {
            //     // 导出Excel
            //     saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'data.xlsx');
            // } catch (e) {
            //     if (typeof console !== 'undefined') console.error(e, wbout);
            // }
            // return wbout;
            this.$http.post("/api/export_excel_room_usage_rate", this.queryForm).then(res => {
                console.log(res)
                window.open(res.data.url, "_blank")
            })
        },

        updateTable() {
            this.key = Math.random()
        },


        downloadTemp() {
            let flag = 1
            let arr = []
            if (!this.importForm.term_id) {
                flag = 0; this.$message.error(`请选择关联学期~`); return;
            }
            this.$http.post("/api/downloadTemp_room_usage_rate", this.queryForm).then(res => {
                console.log(res)
                window.open(res.data.url, "_blank")
            })
        },
        uploadFiles(e) {
            console.log(e)
            this.importForm.fileName = e.fileName
            this.importForm.url = e.src
            this.$forceUpdate()
        },

        saveFormImport(action) {
            let _this = this
            console.log(476, this.importForm.term_id)
            let flag = 1
            let arr = []
            if (!this.importForm.term_id) {
                flag = 0; this.$message.error(`请选择关联学期~`); return;
            }


            let termObj = this._lo.find(_this.termList, function (o) { return o.id == _this.importForm.term_id; })


            if (!this.importForm.url || this.importForm.url == '') {
                flag = 0; this.$message.error(`请选择excel文件并上传~`); return;

            }


            if (flag) {
                console.log(333, arr)
                this.$confirm(`您已选择【${termObj.title}】，是否确定导入该学期的实验室使用率情况？`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {


                    let loadingInstance = this.$loading({
                        lock: true,
                        text: "正在导入，请稍等....",
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.3)',
                    })
                    this.$http
                        .post('/api/import_xls_room_usage_rate', {
                            term_id: this.importForm.term_id,
                            url: this.importForm.url,
                        })
                        .then((res) => {
                            if (res.data.errors && res.data.errors.length > 0) {
                                let html = "";
                                for (let msg of res.data.errors) {
                                    html +=
                                        "<div style='font-size:12px'>" + msg + "</div>";
                                }
                                this.$alert(html, "未导入数据", {
                                    dangerouslyUseHTMLString: true,
                                });
                            } else {
                                this.$message.success("导入成功");
                                this.getList();
                                this.showImportDialog = false;
                            }
                        });
                    loadingInstance.close()

                })
            }

        },
    },
}
</script>

<style scoped="scoped">
.dttable td {
    text-align: center;
}
</style>
